<template>
  <div>
    <!-- <div>Value: {{ decodedString || 'Nothing' }}</div> -->
    <qrcode-stream @decode="onDecode"></qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  name: 'QrCodeReader',
  components: {
    QrcodeStream,
    // QrcodeDropZone,
    // QrcodeCapture
  },
  props: {
    msg: String,
  },
  data() {
    return {
      decodedString: '',
    };
  },
  methods: {
    onDecode(qrValue) {
      this.$emit('onDecode', qrValue);
      this.decodedString = qrValue;
    },
  },
};
</script>
