import Api from '../api';

export const menuApi = {
  ddl(params) {
    return Api.get('menu/picklist', params);
  },
  list(params) {
    return Api.get('menu/list', params);
  },
  detail(params) {
    return Api.get('menu', params);
  },
};

export const menuItemApi = {
  ddl(params) {
    return Api.get('menu/item/picklist', params);
  },
  list(params) {
    return Api.get('menu/item/list', params);
  },
  detail(params) {
    return Api.get('menu/item', params);
  },
  focList(params) {
    return Api.get('menu/item/foc/list', params);
  },
};

export const menuItemOptApi = {
  ddl(params) {
    return Api.get('menu/item/opt/picklist', params);
  },
  list(params) {
    return Api.get('menu/item/opt/list', params);
  },
  detail(params) {
    return Api.get('menu/item/opt', params);
  },
};

export const menuItemOptGrpApi = {
  ddl(params) {
    return Api.get('menu/item/opt/grp/picklist', params);
  },
  list(params) {
    return Api.get('menu/item/opt/grp/list', params);
  },
  detail(params) {
    return Api.get('menu/item/opt/grp', params);
  },
};

export const menuItemOptGrpSubApi = {
  ddl(params) {
    return Api.get('menu/item/opt/grp/sub/picklist', params);
  },
  list(params) {
    return Api.get('menu/item/opt/grp/sub/list', params);
  },
  detail(params) {
    return Api.get('menu/item/opt/grp/sub', params);
  },
};
