<template>
  <div class="badge-wrap d-flex justify-center align-center" :style="{ 'background-color': bgColor, 'color': color }">
    {{ count }}
  </div>
</template>
<script>
export default {
  name: 'Badge',
  props: {
    bgColor: {
      type: String,
      default: '#4caf50',
    },
    color: {
      type: String,
      default: '#fff',
    },
    count: {
      type: [String, Number],
      default: 0,
    },
  }
}
</script>
<style lang="scss" scoped>
.badge-wrap {
  border-radius: 10px;
  font-size: 12px;
  width: 20px;
  height: 20px;
}
</style>