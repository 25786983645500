export default {
  map: {
    deliveryChannel: {
      2: 'Takeaway',
      32: 'Pickup',
      42: 'Delivery',
    },
    orderStatus: {
      0: 'Draft',
      1: 'Closed',
      2: 'Failed',
      3: 'Expired',
      4: 'Voided',
      7: 'Picked up',
      8: 'Ready',
      9: 'Confirmed',
      11: 'Pending Payment',
    },
  },
};
