const data = [
  {
    path: '/list',
    values: [1201],
  },
  {
    path: '/org/list',
    values: [1201],
  },
];

export default data;
