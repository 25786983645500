import Api from '../api';
import { sha256 } from 'js-sha256';

export const authApi = {
  userLogin(data) {
    return Api.post('auth/user/login', data);
  },
  guestLogin(data) {
    return Api.post('auth/guest/login', data);
  },
  setPwd(data) {
    data.password = sha256(data.password);
    data.newPassword = sha256(data.newPassword);
    return Api.post('auth/user/login/set', data);
  },
};
