<template>
  <div class="text-center">
    <div>Value: {{ text || 'Nothing' }}</div>
    <StreamBarcodeReader @decode="(a, b, c) => onDecode(a, b, c)" @loaded="() => onLoaded()"></StreamBarcodeReader>
  </div>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader';

export default {
  name: 'QrBarcodeReader',
  components: {
    StreamBarcodeReader,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      text: '',
      id: null,
    };
  },
  methods: {
    onDecode(a, b, c) {
      console.log(a, b, c);
      this.text = a;
      if (this.id) clearTimeout(this.id);
      this.id = setTimeout(() => {
        if (this.text === a) {
          this.text = '';
        }
      }, 5000);
    },
    onLoaded() {
      console.log('load');
    },
  },
};
</script>
