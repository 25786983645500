<template>
  <v-fade-transition mode="out-in">
    <v-app>
      <div id="snackbar" class="cxlt-toastr-container toast-top-right"></div>
      <router-view />
    </v-app>
  </v-fade-transition>
</template>

<script>
// Styles
import '@/styles/index.scss';

export default {
  name: 'App',
  metaInfo: {
    title: 'Baoger Lab',
    // titleTemplate: '%s | Material Dashboard Free',
    htmlAttrs: { lang: 'en' },
    meta: [{ charset: 'utf-8' }, { name: 'viewport', content: 'width=device-width, initial-scale=1' }, { name: 'keywords', content: 'Bao, Baoger' }, { name: 'description', content: 'The best, juicy & tasty Bao in the town, prepared and perfectly cooked with high quality ingredients' }],
  },
  created() {
    // return this.login_act(data)
    //   .then(async res => {
    //     this.loading = false;
    //     if (res.ret == '0') {
    //       await this.processLoginSuccess_act(res.data);
    //       this.navigateReplace(this.defaultPageAfterLogin);
    //     } else if (res.ret == 'NotInitialized') {
    //       this.step = LOGIN_STEP.SET_PWD;
    //       await this.$refs.observer1.reset();
    //     }
    //   }).catch(() => {
    //     this.loading = false;
    //   });
  },
};
</script>
