/*
Naming convention
  Module: Eg. Supplier, Raw Mat, etc.
  Type: act-action, lbl-label, success, error

  {module}.{type}.{actual wording short form}
  {module}.{sub module}.{type}.{actual wording short form}
*/

import labels from './labels/en';
import maps from './maps/en';
export default {
  ...labels,
  ...maps,
  app: {
    name: 'Baoger Lab',
    org: {
      name: 'BaogerLab',
      storePrefix: 'Baoger',
    }
  },

  'act.add': 'Add',
  'act.addToCart': 'Add to Cart',
  'act.apply': 'Apply',
  'act.back': 'Back',
  'act.cancel': 'Cancel',
  'act.confirm': 'Confirm',
  'act.checkout': 'Checkout',
  'act.next': 'Next',
  'act.ok': 'OK',
  'act.proceed': 'Proceed',

  'bar.main.lbl.aboutUs': 'About Us',
  'bar.main.lbl.ourCoffee': 'Our Coffee',
  'bar.main.lbl.ourIngredients': 'Our Ingredients',
  'bar.main.lbl.ourMenu': 'Our Menu',
  'bar.main.lbl.location': 'Location',

  'cart.act.addMore': 'Add More',
  'cart.act.placeOrder': 'Place Order',
  'cart.success.add': 'Added to cart successfully',
  'cart.success.order.confirm': 'Order has been confirmed',

  'checkout.err.locNotAvailable': 'Sorry, we are unable to serve your order location temporarily.',
  'checkout.lbl.orderInAdvance': 'Order in Advance',
  'checkout.lbl.orderNow': 'Order Now',

  'delivery.lbl.delivery': 'Delivery',
  'delivery.lbl.eta': 'ETA',
  'delivery.lbl.deliveryTime': 'Delivery Time',
  'delivery.lbl.selectTimeSlot': 'Select Time Slot',

  'header.lbl.cart': 'Cart',
  'header.lbl.cashPool': 'Cash Pool',
  'header.lbl.stock': 'Stock',
  'header.lbl.orders': 'Orders',

  'lbl.date': 'Date',
  'lbl.now': 'Now',
  'lbl.of': 'of',
  'lbl.results': 'Results',
  'lbl.rm': 'RM',
  'lbl.remarks': 'Remarks',
  'lbl.sessionTimeout': 'Session Timeout',
  'lbl.select': 'Please select',
  'lbl.store': 'Store',
  'lbl.pickupLocation': 'Pickup Location',
  'lbl.total': 'Total',
  'lbl.totalAmt': 'Total Amount',
  'lbl.time': 'Time',
  'lbl.timeSlot': 'Time Slot',

  'login.act.login': 'Login',
  'login.act.logout': 'Logout',
  'login.act.setPassword': 'Set Password',
  'login.lbl.confirmNewPassword': 'Confirm New Password',
  'login.lbl.newPassword': 'New Password',
  'login.lbl.password': 'Password',
  'login.lbl.setPassword': 'Set Password',
  'login.lbl.username': 'Username',

  'mainMenu.act.exploreMenu': 'Explore the menu now',
  'mainMenu.lbl.aboutUs': 'About Us',
  'mainMenu.lbl.location': 'Location',
  'mainMenu.lbl.ourCoffee': 'Our Coffee',
  'mainMenu.lbl.ourIngredients': 'Our Ingredients',
  'mainMenu.lbl.ourMenu': 'Our Menu',
  'mainMenu.location.lbl.desc': 'Follow our social media, we will walk around in the Klang Valley area. Stay Tune!',
  'mainMenu.ourIngredients.lbl.desc': 'Our Main goal at Baoger Lab is to ensure our customer enjoy a tasty Baoger made from the freshest and healthy ingredients.',
  'mainMenu.ourIngredients.1.lbl.title': "Our Vege",
  'mainMenu.ourIngredients.1.lbl.desc': "All the vegetables we use in the Baoger Lab menu’s are freshly prepared to ensure the vegetables comply with our food safety and quality standards.",
  'mainMenu.ourIngredients.2.lbl.title': "Our Chicken",
  'mainMenu.ourIngredients.2.lbl.desc': "All the chicken going into the Baos comes from our local chicken farm certified Halal by JAKIM, to allow everyone to enjoy our delicious and juicy chicken.",
  'mainMenu.ourIngredients.3.lbl.title': "Our Bao",
  'mainMenu.ourIngredients.3.lbl.desc': "The steamed buns used in Baoger Lab, are from our local supplier which is certified Halal by JAKIM.",

  'menu.lbl.aLaCarte': 'À la carte',
  'menu.lbl.beverage': 'Beverage',

  'msg.error.recordNotFound': 'Record not found',

  'payment.lbl.paymentChannel': 'Payment Channel',
  'payment.lbl.paymentMethod': 'Payment Method',

  'profile.lbl.address': 'Address',
  'profile.lbl.address2': 'Address 2',
  'profile.lbl.address3': 'Address 3',
  'profile.lbl.city': 'City',
  'profile.lbl.email': 'Email',
  'profile.lbl.phoneNo': 'Phone Number',
  'profile.lbl.postcode': 'Postcode',
  'profile.lbl.state': 'State',
  'profile.lbl.unitBlockHouse': 'Unit/Block/House No',
  'profile.lbl.yourName': 'Your Name',

  'promo.lbl.promoCode': 'Promo Code',
  'promo.lbl.enterPromoCode': 'Enter promo code',

  'stocks.lbl.bal': 'Balance',
  'stocks.lbl.item': 'Item',
  'stocks.lbl.par': 'PAR',
  'stocks.lbl.ordered': 'Ordered',
  'stocks.lbl.total': 'Total',

  'store.lbl.cashAmt': 'Cash Amount',
  'store.lbl.selectStore': 'Select Store',
  'store.lbl.pickupLocation': 'Pickup Location',

  'header.checkout': 'Checkout',

  'orders.act.cancelOrder': 'Cancel Order',
  'orders.act.choosePymtMethod': 'Choose Payment Method',
  'orders.act.collect': 'Collect',
  'orders.act.complete': 'Complete',
  'orders.act.orderNow': 'Order Now',
  'orders.lbl.cancelled': 'Cancelled',
  'orders.lbl.cancelOrder': 'Cancel Order',
  'orders.lbl.channel': 'Channel',
  'orders.lbl.cartEmpty': 'Your cart is empty',
  'orders.lbl.deliveryChannel': 'Delivery Channel',
  'orders.lbl.deliveryFee': 'Delivery Fee',
  'orders.lbl.food': 'Food',
  'orders.lbl.item': 'Item',
  'orders.lbl.orderNo': 'Order No',
  'orders.lbl.quantity': 'Quantity',
  'orders.lbl.subtotal': 'Subtotal',
  'orders.success.cancel': 'Order has been cancelled',
  'orders.success.collect': 'Order collected',
  'orders.success.complete': 'Order completed',
  'orders.tab.collectQueue': 'Collect Queue',
  'orders.tab.orderQueue': 'Order Queue',
  'orders.tab.prepareList': 'Prepare List',

  'pickup.lbl.pickup': 'Pickup',
  'pickup.lbl.pickupDate': 'Pickup Date',
  'pickup.lbl.pickupLocation': 'Pickup Location',
  'pickup.lbl.pickupTime': 'Pickup Time',
  'pickup.lbl.selectDate': 'Select Date',
  'pickup.lbl.selectTimeSlot': 'Select Time Slot',

  'pymt.act.payAtCounter': 'Pay at counter',
  'pymt.act.payOnline': 'Pay Online',
  'pymt.act.payNow': 'Pay Now',

  'route.Cart': 'Cart',
  'route.Stocks': 'Stocks',
  'route.Orders': 'Orders',

  opt: {
    deliveryChannel: [
      // { text: 'Takeaway', value: 2 },
      { text: 'Pickup', value: 32 },
      { text: 'Delivery', value: 42 },
    ]
  }
};
