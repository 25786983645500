import AppStorage from '@/plugins/storage/index.js';
import { isUndefinedNullOrEmpty } from '@/util/tools';
import { promoApi } from '@/api/promo';

const promo = {
  state: {
    selectedPromo: AppStorage.local.get('selectedPromo') || '',
    selectedPromoInfo: AppStorage.local.get('selectedPromoInfo') || {},
    promoList: AppStorage.local.get('promoList') || {},
    promoListShow: false,
  },
  getters: {
    getSelectedPromo: state => {
      return state.selectedPromo;
    },
    getSelectedPromoInfo: state => {
      return !isUndefinedNullOrEmpty(state.selectedPromoInfo) ? JSON.parse(state.selectedPromoInfo) : {};
    },
    getPromoList: state => {
      return !isUndefinedNullOrEmpty(state.promoList) ? JSON.parse(state.promoList) : {};
    },
  },
  mutations: {
    SET_SELECTED_PROMO(state, payload) {
      state.selectedPromo = payload;
      payload ? AppStorage.local.set('selectedPromo', payload) : AppStorage.local.remove('selectedPromo');
    },
    SET_SELECTED_PROMO_INFO(state, payload) {
      state.selectedPromoInfo = JSON.stringify(payload);
      payload ? AppStorage.local.set('selectedPromoInfo', JSON.stringify(payload)) : AppStorage.local.remove('selectedPromoInfo');
    },
    SET_PROMO_LIST(state, payload) {
      state.promoList = JSON.stringify(payload);
      payload ? AppStorage.local.set('promoList', JSON.stringify(payload)) : AppStorage.local.remove('promoList');
    },
    SET_PROMO_LIST_SHOW(state, payload) {
      state.promoListShow = payload;
    },
  },
  actions: {
    async checkPromo({ getters, dispatch }, { amt, codes, userId }) {
      if (isUndefinedNullOrEmpty(amt)) {
        throw 'Missing param: amt';
      }

      let checkNewCode = false,
        codesArr = [];

      if (isUndefinedNullOrEmpty(codes)) {
        for (let key in getters.getPromoList) {
          codesArr.push(key);
        }
      } else {
        checkNewCode = true;
        codesArr = [codes];
      }

      if (isUndefinedNullOrEmpty(userId)) userId = getters.getOrderUserId ? Number(getters.getOrderUserId) : null;

      const data = { amt, codes: codesArr, userId };
      const res = await promoApi.checkPromo(data);

      if (checkNewCode) {
        return await dispatch('_processNewPromo', res.data);
      } else {
        return await dispatch('_processExistingPromo', res.data);
      }
    },
    async _processNewPromo({ commit, getters }, { preferred, codes }) {
      // Assuming only one code in codes obj
      if (!isUndefinedNullOrEmpty(preferred)) {
        if (!isUndefinedNullOrEmpty(codes)) {
          const preferredCodeObj = {
            [preferred]: {
              ...codes[preferred],
            },
          };

          commit('SET_SELECTED_PROMO', preferred);
          commit('SET_SELECTED_PROMO_INFO', preferredCodeObj);

          const firstKey = Object.keys(codes)[0];
          let latestCode = getters.getPromoList;
          latestCode[firstKey] = codes[firstKey];

          commit('SET_PROMO_LIST', latestCode);
          return true;
        }
      }
      throw 'Invalid Code';
    },
    async _processExistingPromo({ commit }, { preferred, codes }) {
      if (!isUndefinedNullOrEmpty(preferred)) {
        if (!isUndefinedNullOrEmpty(codes)) {
          const preferredCodeObj = {
            [preferred]: {
              ...codes[preferred],
            },
          };
          commit('SET_SELECTED_PROMO', preferred);
          commit('SET_SELECTED_PROMO_INFO', preferredCodeObj);

          commit('SET_PROMO_LIST', codes);
          return true;
        }
      }
      commit('SET_SELECTED_PROMO', '');
      commit('SET_SELECTED_PROMO_INFO', null);
      return false;
    },
  },
};

export default promo;
