import Api from '../api';

export const storeApi = {
  ddl(params) {
    return Api.get('store/picklist', params);
  },
  list(params) {
    return Api.get('store/list', params);
  },
  detail(params) {
    return Api.get('store', params);
  },
};