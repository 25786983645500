import Api from '../api';

export const orderApi = {
  place(data) {
    return Api.post('order/place', data);
  },
  void(data) {
    return Api.post('order/void', data);
  },
  confirm(data) {
    return Api.post('order/confirm', data);
  },
  getSeq(data) {
    return Api.get('order/seq', data);
  },
  detail(data) {
    return Api.get('order', data);
  },
};

export const orderPayApi = {
  payCounter(data) {
    return Api.post('order/pay/counter', data);
  },
  payOnline(data) {
    return Api.post('order/pay/online', data);
  },
};

export const orderPickupLocApi = {
  ddl(params) {
    return Api.get('pickup/loc/picklist', params);
  },
  list(params) {
    return Api.get('pickup/loc/list', params);
  },
  detail(params) {
    return Api.get('pickup/loc', params);
  },
};

export const orderDeliveryApi = {
  quotation(data) {
    return Api.post('delivery/quotation', data);
  },
  getBizDate(params) {
    return Api.get('gis/loc/biz-date/list', params);
  },
};
