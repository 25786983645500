import { storeApi } from '@/api/store';
import AppStorage from '@/plugins/storage/index.js';

const store = {
  state: {
    storeDdlList: [],
    storeDdlMap: {},
    storeId: null,
    storeName: '',
    preSelectedChannelFlag: AppStorage.session.get('preSelChannelFlag'),
    preSelectedChannel: AppStorage.session.get('preSelChannel'),
    preSelectedLoc: AppStorage.session.get('preSelLoc'),
    preSelectedLocModalShow: false,
  },
  getters: {
    getStoreId: state => {
      return state.storeId ? state.storeId : AppStorage.local.get('storeId');
    },
    getStoreName: state => {
      return state.storeName ? state.storeName : AppStorage.local.get('storeName');
    },
    getPreSelectedChannelFlag: state => {
      return state.preSelectedChannelFlag ? state.preSelectedChannelFlag : AppStorage.session.get('preSelChannelFlag');
    },
    getPreSelectedChannel: state => {
      return state.preSelectedChannel ? state.preSelectedChannel : AppStorage.session.get('preSelChannel');
    },
    getPreSelectedLoc: state => {
      return state.preSelectedLoc ? state.preSelectedLoc : AppStorage.session.get('preSelLoc');
    },
  },
  mutations: {
    SET_STORE_DDL_LIST(state, payload) {
      state.storeDdlList = payload;
    },
    SET_STORE_DDL_MAP(state, payload) {
      state.storeDdlMap = payload;
    },
    SET_STORE_ID(state, payload) {
      state.storeId = payload;
      payload ? AppStorage.local.set('storeId', payload) : AppStorage.local.remove('storeId');
    },
    SET_STORE_NAME(state, payload) {
      state.storeName = payload;
      AppStorage.local.set('storeName', payload);
    },
    SET_PRE_SELECTED_CHANNEL_FLAG(state, payload) {
      state.preSelectedChannelFlag = payload;
      payload ? AppStorage.session.set('preSelChannelFlag', payload) : AppStorage.session.remove('preSelChannelFlag');
    },
    SET_PRE_SELECTED_CHANNEL(state, payload) {
      state.preSelectedChannel = payload;
      payload ? AppStorage.session.set('preSelChannel', payload) : AppStorage.session.remove('preSelChannel');
    },
    SET_PRE_SELECTED_LOC(state, payload) {
      state.preSelectedLoc = payload;
      payload ? AppStorage.session.set('preSelLoc', payload) : AppStorage.session.remove('preSelLoc');
    },
    SET_PRE_SELECTED_LOC_MODAL_SHOW(state, payload) {
      state.preSelectedLocModalShow = payload;
    },
  },
  actions: {
    fetchStoreDdl({ commit }, data) {
      return storeApi.ddl(data).then(response => {
        const data = response.data;
        let list = [],
          map = {};
        for (let item of data) {
          list.push({ text: item.name, value: item.id });
          map[item.id] = item.name;
        }
        commit('SET_STORE_DDL_LIST', list);
        commit('SET_STORE_DDL_MAP', map);
        return response;
      });
    },
  },
};
export default store;
