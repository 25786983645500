import { lookupApi } from '@/api/lookup';

const lookup = {
  state: {
    onlinePayMethodList: [],
    deliveryChannelList: [],
    deliveryChannelMap: {},
  },
  getters: {},
  mutations: {
    SET_ONLINE_PAY_METHOD_LIST(state, payload) {
      state.onlinePayMethodList = payload;
    },
    SET_DELIVERY_CHANNEL_LIST(state, payload) {
      state.deliveryChannelList = payload;
    },
    SET_DELIVERY_CHANNEL_MAP(state, payload) {
      state.deliveryChannelMap = payload;
    },
  },
  actions: {
    fetchOnlinePayMethodList({ commit }, data) {
      return lookupApi.onlinePayMethodList(data).then(response => {
        const data = response.data;
        const list = data.map(item => {
          return {
            text: item.name,
            value: item.id,
          };
        });
        commit('SET_ONLINE_PAY_METHOD_LIST', list);
        return response;
      });
    },
    fetchDeliveryChannelList({ commit }, data) {
      return lookupApi.deliveryChannelList(data).then(response => {
        const data = response.data;
        let list = [],
          map = {};
        for (let item of data) {
          list.push({ text: item.name, value: item.id });
          map[item.id] = item.name;
        }
        commit('SET_DELIVERY_CHANNEL_LIST', list);
        commit('SET_DELIVERY_CHANNEL_MAP', map);
        return response;
      });
    },
  },
};
export default lookup;
