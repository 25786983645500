<template>
  <CommonModal v-model="show" :modal-size="'modal-xs'" :header="false" :footer="false">
    <template #body>
      <div class="modal-body d-flex align-center">
        <v-icon class="mr-2" color="var(--primary-color)" size="60">mdi-check-circle-outline</v-icon>
        <div class="modal-body__text">{{ msg }}</div>
      </div>
    </template>
  </CommonModal>
</template>
<script>
import CommonModal from './index';
export default {
  name: 'SuccessModal',
  props: {
    msg: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: null,
    },
  },
  components: {
    CommonModal,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.modal-body {
  background-color: #231f20;
  height: 100px;

  &__text {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 600;
  }
}
</style>