import Api from '../api';

export const promoApi = {
  checkPromo(data) {
    return Api.post('promo/code/check', data);
  },
  sharePromo(params) {
    return Api.get('promo/code/share', params);
  },
};
