<template>
  <div>
    <div>Value: {{ text || 'Nothing' }}</div>
    <ImageBarcodeReader @decode="onDecode" @error="onError"></ImageBarcodeReader>
  </div>
</template>

<script>
import { ImageBarcodeReader } from 'vue-barcode-reader';

export default {
  name: 'QrBarcodeImg',
  components: {
    ImageBarcodeReader,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      text: '',
    };
  },
  methods: {
    onDecode(result) {
      this.text = result;
      console.log(result);
    },
    onError(error) {
      console.log(error);
    },
  },
};
</script>
