export default {
  // 'labels.2FAAuth': 'Two Factor Authentication',
  // 'labels.2FAAuthDesc': 'Enable now to secure your account with stronger protection',
  // 'labels.accAccount.registered': 'Account Registered',
  // 'labels.acceptedFormatDesc': 'Accepted file formats: {accept}. Max file size {size}.',
  // 'labels.accInactive': 'Your account is inactive',
  // 'labels.accLockDesc': 'Your account has been locked , please check your email.',
  // 'labels.accountId': 'Account ID',
  // 'labels.action': 'Action',
  // 'labels.activated': 'Activated',
  // 'labels.activateSuccess': 'Activate successfully!',
  // 'labels.activateUser': 'Activate user',
  // 'labels.add': 'Add',
  // 'labels.addAnother': 'Add Another',
  // 'labels.addAnotherDoc': 'Add Another Document',
  // 'labels.addContact': 'Add contact',
  // 'labels.addContactSuccess': 'Add Contact Successfully!',
  // 'labels.addedOn': 'Added on',
  // 'labels.addNew': 'Add New',
  // 'labels.addNewLevel.desc': 'Add each level only once. Multiple persons can sign on behalf of the same level.',
  // 'labels.addNewLevel': 'Add New Level',
  // 'labels.addNotes': 'Add Notes (Optional)',
  // 'labels.addOn': 'Add-On',
  // 'labels.addRequirementDesc1': 'Adding an organization that you are related to create ',
  // 'labels.address': 'Address',
  // 'labels.addSigner': 'Add Signer',
  // 'labels.addsignerSuccess': 'Add Signer Successfully',
  // 'labels.addStamp': 'Add Stamp',
  // 'labels.addTag': 'Add Tag (Optional)',
  // 'labels.addUserSuccess': 'Added user successfully!',
  // 'labels.addViewer': 'Add Viewer',
  // 'labels.addYears': 'add {value} years',
  // 'labels.admin.dashboard': 'Admin Dashboard',
  // 'labels.advancedOptions': 'ADVANCED OPTIONS',
  // 'labels.ago': 'ago',
  // 'labels.agreeTo': 'I agree to the',
  // 'labels.all': 'All',
  // 'labels.allAppendable': 'All {appendable}',
  // 'labels.allowToChange': 'Allow to change',
  // 'labels.allowToRemove': 'Allow to remove',
  // 'labels.alrHaveAccDesc': 'Already have an account ?',
  // 'labels.and': 'and',
  // 'labels.appCreatePwd': 'Create password',
  // 'labels.appFirstLogin': 'First login',
  // 'labels.appForgetPassword': 'Forgot Password',
  // 'labels.appLogin': 'Login',
  // 'labels.appReAuth': 'Re-Authenticate',
  // 'labels.appResetPwd': 'Reset password',
  // 'labels.appSignIn': 'Sign in',
  // 'labels.appSignUp': 'Sign up',
  // 'labels.assign': 'Assign',
  // 'labels.asAuthSigner': 'as authorized signer',
  // 'labels.assignSigner': 'Assign Signer',
  // 'labels.assignSigner.workspace.desc': 'Signer sign as an individual user or on behalf of an organization listed below',
  // 'labels.attempLeft': 'Please re-enter the security code again',
  // 'labels.auditTrails.event.Add Signer': '{name}[{email}] added {target}[{targetEmail}] as signer',
  // 'labels.auditTrails.event.Add Viewer': '{name}[{email}] added {target}[{targetEmail}] as viewer',
  // 'labels.auditTrails.event.Amended': '{name}[{email}] amended the event',
  // 'labels.auditTrails.event.Completed': 'Event has been completed',
  // 'labels.auditTrails.event.Created': '{name}[{email}] created event ',
  // 'labels.auditTrails.event.Edit Viewer Permission': '',
  // 'labels.auditTrails.event.Event Reminder': 'System sent reminder to {target}[{targetEmail}]',
  // 'labels.auditTrails.event.Expired': 'Event has expired',
  // 'labels.auditTrails.event.KIV': 'KIV',
  // 'labels.auditTrails.event.Rejected': '{name}[{email}] rejected to sign',
  // 'labels.auditTrails.event.Remove Signer': '{name}[{email}] removed {target}[{targetEmail}] from signer list',
  // 'labels.auditTrails.event.Remove Viewer': '{name}[{email}] remove {target}[{targetEmail}] from viewer list',
  // 'labels.auditTrails.event.Renewal': '',
  // 'labels.auditTrails.event.Request Amendment': '',
  // 'labels.auditTrails.event.Request Termination': '',
  // 'labels.auditTrails.event.Reviewed': '{name}[{email}] reviewed the document [doc name]',
  // 'labels.auditTrails.event.Sign Invitation': 'System sent signing invitation to {target}[{targetEmail}]',
  // 'labels.auditTrails.event.Signed': '{name}[{email}] signed the event',
  // 'labels.auditTrails.event.Submitted': '{name}[{email}] submitted event ',
  // 'labels.auditTrails.event.Terminated': '{name}[{email}] cancelled the event',
  // 'labels.auditTrails.event.Verified': '',
  // 'labels.auditTrails.event.View Invitation': 'System sent viewing invitation to {target}[{targetEmail}]',
  // 'labels.auditTrails.event.Viewed': '{name}[{email}] viewed the event ',
  // 'labels.auditTrails.event.Voided': '{name}[{email}] cancelled the event',
  // 'labels.authorize': 'Authorize',
  // 'labels.authorized': 'Authorized',
  // 'labels.authorizedUser': 'Authorized User',
  // 'labels.authorizeSigner.success': 'Selected signer has been authorize successfully',
  // 'labels.authorizeUser': 'Authorize User',
  // 'labels.autoRedeem.desc': 'Use referral bonus for any purchase',
  // 'labels.autoRedeem': 'Auto Redeem',
  // 'labels.autoReminder.desc': 'Send reminder every 3 days',
  // 'labels.autoReminder': 'Auto Reminder',
  // 'labels.back': 'Back',
  // 'labels.backTo.myProfile': 'Back To My Profile',
  // 'labels.backToLogin': 'Back To Login',
  // 'labels.balance': 'Balance',
  // 'labels.billing': 'Biling',
  // 'labels.bindOrgUser.success': 'Your organization account {email} has successfully bind to organization {name}',
  // 'labels.bindSuccess': 'Your email has been successfully bind to <span style="color:var(--purple)">{email}</span> at <b>{name}</b>',
  // 'labels.blockChain.details': 'Blockchain Details',
  // 'labels.bonusCredit.totalAvailable': 'Total Availale Bonus Credits',
  // 'labels.browse': 'Browse',
  // 'labels.cancel': 'Cancel',
  // 'labels.cancelEvent.confirm': 'Are you sure to cancel this event?',
  // 'labels.cancelEvent.reason.changeDate': 'The need to change event date',
  // 'labels.cancelEvent.reason.duplicate': 'This is an duplicate event',
  // 'labels.cancelEvent.reason.modifyEvent': 'The need to modify event',
  // 'labels.cancelEvent.reason.withoutReason': 'Cancel event without a reason',
  // 'labels.cancelEvent.success.message': 'You had successfully cancelled the event. System also sent an notification automatically to inform all Signers and Viewers.',
  // 'labels.cancelEvent.toastr.success': '{name} canceled document {doc}. The event is now terminated.',
  // 'labels.cancelEvent': 'Cancel Event',
  // 'labels.cannotAssignSigner': 'Email has already been assigned as signer / viewer',
  // 'labels.checkbox': 'Checkbox',
  // 'labels.choosePlan': 'Choose Plan',
  // 'labels.city': 'City',
  // 'labels.comment': 'Comment',
  // 'labels.company': 'Company',
  // 'labels.companyCert.requirement': 'Accepted file formats: .PDF. Max file size 5MB',
  // 'labels.companyCert': 'Company Certification',
  // 'labels.companyReg': 'Company Registration',
  // 'labels.companyStamp.requirement': 'Accepted file formats: GIF, JPG, PNG, BMP. Max file size 200KB.',
  // 'labels.companyStamp': 'Company Stamp',
  // 'labels.completeKycAction.msg': 'Please complete your ID verification to proceed further',
  // 'labels.completeReqThroughApp': 'For complete this requirement we require you to install BDSS mobile app.',
  // 'labels.completeYourProfile': 'Complete your profile',
  // 'labels.confirm': 'Confirm',
  // 'labels.confirmActivateAllUser': 'Confirm activate all user?',
  // 'labels.confirmActivateUser': 'Confirm activate user?',
  // 'labels.confirmAddContact': 'Are you sure to add this contact?',
  // 'labels.confirmAuthorizeAllUser': 'Confirm authorize all user?',
  // 'labels.confirmAuthorizeUser': 'Confirm authorize these user?',
  // 'labels.confirmChanges': 'Confirm changes?',
  // 'labels.confirmDeactivateAllUser': 'Confirm deactivate all user?',
  // 'labels.confirmDeactivateUser': 'Confirm deactivate user?',
  // 'labels.confirmDeleteAllContact': 'Are you sure to delete all contact?',
  // 'labels.confirmDeleteAllSigner': 'Confirm delete all signers',
  // 'labels.confirmDeleteAllUser': 'Confirm delete all users',
  // 'labels.confirmDeleteContact': 'Are you sure to delete this contact?',
  // 'labels.confirmDeleteSigner': 'Confirm delete these signers',
  // 'labels.confirmDeleteUser': 'Confirm delete these users',
  // 'labels.confirmEditContact': 'Are you sure to edit this contact?',
  // 'labels.confirmPwd': 'Confirm Password',
  // 'labels.confirmUnauthorizeAllUser': 'Confirm unauthorize all user?',
  // 'labels.confirmUnauthorizeUser': 'Confirm unauthorize these user?',
  // 'labels.contact': 'Contact',
  // 'labels.contactUs': 'Contact Us',
  // 'labels.continue': 'Continue',
  // 'labels.contractAddSuccess': 'Contract added successfully!',
  // 'labels.contractSaveDraftMsg': 'Would you like to save current edit as draft?',
  // 'labels.copyright': 'Copyright © Signitory 2021 All rights reserved.',
  // 'labels.copyToClipboard': 'Copied to clipboard!',
  // 'labels.countries': 'Countries',
  // 'labels.country': 'Country',
  // 'labels.create': 'Create',
  // 'labels.createdBy': 'Created By',
  // 'labels.createdDate': 'Created Date',
  // 'labels.createdOn': 'Created on {value}',
  // 'labels.createEvent': 'Create Event',
  // 'labels.createFirstSignature': 'Create your first signature now',
  // 'labels.createNew': 'Create New',
  // 'labels.createNewDoc': 'Create New Document',
  // 'labels.createNewDocDesc': 'use a template and start editing',
  // 'labels.createNewSignature': 'Create New Signature',
  // 'labels.createNewStamp': 'Create New Stamp',
  // 'labels.createSigSuccess': 'Signatured created successfully',
  // 'labels.createTemplate': 'Create Template',
  // 'labels.creationDate': 'Creation Date',
  // 'labels.currentTotal': 'Current Total',
  // 'labels.dash.createdOn': 'Created On',
  // 'labels.dashboard.allEvent': 'All Event',
  // 'labels.dashboard.cancelled': 'Cancelled',
  // 'labels.dashboard.completed': 'Completed',
  // 'labels.dashboard.created': 'Created',
  // 'labels.dashboard.expired': 'Expired',
  // 'labels.dashboard.ongoing': 'On-Going',
  // 'labels.dashboard.rejected': 'Rejected',
  // 'labels.dashboard': 'Dashboard',
  // 'labels.dateSigned': 'Date Signed',
  // 'labels.day': 'day',
  // 'labels.days': 'days',
  // 'labels.deactivateUser': 'Suspend user',
  // 'labels.default': 'Default',
  // 'labels.defaultSigSuccess': 'Default signature added successfully',
  // 'labels.defineSigner': 'Define Signer',
  // 'labels.delete': 'Delete',
  // 'labels.deleteAllSigner': 'Delete All Signer',
  // 'labels.deleteContactSuccessfully': 'Contact deleted successfully!',
  // 'labels.deleteDraft': 'Delete Draft',
  // 'labels.deleteDraftConfirm': 'Are you sure to delete draft?',
  // 'labels.deleteDraftSuccess': 'Draft deleted successfully',
  // 'labels.deleteSigner': 'Delete Signer',
  // 'labels.deletesignerSuccessfully': 'Selected signer has been deleted successfully',
  // 'labels.deleteSigSuccess': 'Signatured deleted successfully',
  // 'labels.deleteTemplate': 'Delete Template',
  // 'labels.deleteTemplateConfirm': 'Are you sure to delete template?',
  // 'labels.deleteTemplateSuccess': 'Template deleted successfully',
  // 'labels.deleteUserSuccessfully': 'Delete user successfully!',
  // 'labels.deleting': 'Deleting',
  // 'labels.description': 'Description',
  // 'labels.desktopNotification': 'Desktop Notifications',
  // 'labels.desktopNotificationDesc': 'Enable your desktop notification for better experience',
  // 'labels.detials': 'Details',
  // 'labels.discardDraft': 'Discard Draft',
  // 'labels.docType.notMatch': 'Document Type is not matched with URL.',
  // 'labels.document.details': 'Document Details',
  // 'labels.document': 'Document',
  // 'labels.documentSigned.msg': 'Your document is successfully signed.',
  // 'labels.documentSigned': 'Document Signed',
  // 'labels.domainURL': 'Domain URL',
  // 'labels.dontHaveAcc': `Don't have an account ?`,
  // 'labels.download': 'Download',
  // 'labels.downloadReport': 'Download Report',
  // 'labels.downloadSigNow': 'Download Signatory Now',
  // 'labels.draft': 'Draft',
  // 'labels.dragFileToUpload': 'Drag files to upload or',
  // 'labels.earlier': 'Earlier',
  // 'labels.edit': 'Edit',
  // 'labels.editCompanyProfile.confirmEdit': 'Are you sure to save edited company profile?',
  // 'labels.editCompanyProfile': 'Edit Company Profile',
  // 'labels.editContact': 'Edit contact',
  // 'labels.editContactSuccess': 'Edit Contact Successfully!',
  // 'labels.editExpiryDate': 'Edit Expiry Date',
  // 'labels.editMyProfile': 'Edit My Profile',
  // 'labels.editSigner': 'Edit Signer',
  // 'labels.editsignerSuccess': 'Update signer information successfully!',
  // 'labels.editUserSuccess': 'Update user information successfully!',
  // 'labels.email': 'Email',
  // 'labels.emailAddress': 'Email Address',
  // 'labels.emailInSignerList': 'Email already in signer list',
  // 'labels.emailIsHost': 'Host cannot be added as viewer',
  // 'labels.emailListMgmt': 'Email List Management',
  // 'labels.emailOTP': 'Email OTP',
  // 'labels.emailSentSuccessfully': 'Email sent successfully ',
  // 'labels.emailVerification': 'Email Verification',
  // 'labels.enablePremiumContactDesc': 'Free version is maximum of 15 contacts only. Start your 7 days FREE trial and gain access to all of our premium features.',
  // 'labels.ensureIsCompAddres': 'Kindly ensure the entered is an official company email address',
  // 'labels.enterprise.addSigner': 'Confirm add signer',
  // 'labels.enterprise.addUser': 'Add User',
  // 'labels.enterprise.addUserCond': 'This option is unavailable. Your activated user list is full.',
  // 'labels.enterprise.confirmAddSigner': 'Are you sure you want to add this signer?',
  // 'labels.enterprise.confirmAuthorizeSigner': 'Are you sure you want to authorize these signer?',
  // 'labels.enterprise.confirmAddUser': `Confirm add user`,
  // 'labels.enterprise.confirmEditSigner': 'Are you sure you want to edit this signer?',
  // 'labels.enterprise.confirmEditUser': `Confirm edit this user's information`,
  // 'labels.enterprise.editSigner': 'Confirm edit signer',
  // 'labels.enterprise.editUser': 'Edit User',
  // 'labels.enterprise.plan.current': 'Current Plan',
  // 'labels.enterprise.plan.info': 'Plan Info',
  // 'labels.enterprise.signermgmt.activeUser': 'Active User',
  // 'labels.enterprise.signermgmt.allSigner': 'All Signer',
  // 'labels.enterprise.signermgmt.authorizedSigner': 'Authorized signer',
  // 'labels.enterprise.signermgmt.authorizedSigners': 'Authorized Signers',
  // 'labels.enterprise.signermgmt.title': 'Signer Management',
  // 'labels.enterprise.signermgmt.unAuthorizedSigner': 'Un-authorized Signer',
  // 'labels.enterprise.sub.accNo': 'Account No.',
  // 'labels.enterprise.sub.accumulation': 'Accumulation',
  // 'labels.enterprise.sub.addOn': 'Add-on',
  // 'labels.enterprise.sub.bal': 'Balance',
  // 'labels.enterprise.sub.companyStamps': 'Company Stamps',
  // 'labels.enterprise.sub.createDate': 'Creation Date',
  // 'labels.enterprise.sub.creditBal': 'Credit Balance',
  // 'labels.enterprise.sub.currentPlan': 'Current Plan',
  // 'labels.enterprise.sub.eventByAccumulation': 'Event',
  // 'labels.enterprise.sub.expiry': 'Expiry',
  // 'labels.enterprise.sub.exploreDesc': 'Explore all the plans now and gain access to all of our premium features.',
  // 'labels.enterprise.sub.info': 'Subscription Info',
  // 'labels.enterprise.sub.item': 'Item',
  // 'labels.enterprise.sub.orgInfo': 'Organization Info',
  // 'labels.enterprise.sub.orgName': 'Org. Name',
  // 'labels.enterprise.sub.plan': 'Subscription Plan',
  // 'labels.enterprise.sub.planQuota': `Your Plan's Quota`,
  // 'labels.enterprise.sub.planQuotaDesc': `Signitory offers you tremendous bandwidth in managing events,starting with our free-to-use plan. These are the current usage of your plan's quota.`,
  // 'labels.enterprise.sub.status': 'Status',
  // 'labels.enterprise.sub.storage': 'Storage',
  // 'labels.enterprise.sub.subscribed': 'Subscribed',
  // 'labels.enterprise.sub.template': 'Template',
  // 'labels.enterprise.sub.topUpNowDesc': 'Top up now to add credits and extend your account validity.',
  // 'labels.enterprise.sub.used': 'Used',
  // 'labels.enterprise.sub.user': 'User',
  // 'labels.enterprise.usermgmt.activatedUser': 'Activated User',
  // 'labels.enterprise.usermgmt.activateUserSuccess': 'User activated successfully',
  // 'labels.enterprise.usermgmt.activeUser': 'Active User',
  // 'labels.enterprise.usermgmt.activeUserLimitReach': 'Active User Limit Reached',
  // 'labels.enterprise.usermgmt.deactivatedUser': 'Deactivated User',
  // 'labels.enterprise.usermgmt.deactivateUserSuccess': 'User deactivated successfully',
  // 'labels.enterprise.usermgmt.goPurchaseActiveUser': 'Go to purchase active user',
  // 'labels.enterprise.usermgmt.inactiveUser': 'Inactive user',
  // 'labels.enterprise.usermgmt.selectRole': 'Select a Role',
  // 'labels.enterprise.usermgmt.suspendedUser': 'Suspended user',
  // 'labels.enterprise.usermgmt.terminatedUser': 'Terminated User',
  // 'labels.enterprise.usermgmt.terminatedUserSuccessfully': 'Terminated User',
  // 'labels.enterprise.usermgmt.title': 'User Management',
  // 'labels.enterprise.usermgmt.userLimitReach': 'The maximun number of active user has been activated. Try to deactivate few of user or purchase more. Current limit up to {amount}.',
  // 'labels.enterpriseAccount.desc': 'Create your organization now and enjoy more awesome features.',
  // 'labels.enterpriseAccount': 'Enterprise Account',
  // 'labels.enterReason': 'Enter Reason',
  // 'labels.error.assignRole': 'Please select a role for the signer',
  // 'labels.error.invalidURL': 'Invalid URL',
  // 'labels.error.maxSize': 'File size exceeds {value}',
  // 'labels.error.maxViewerCount': 'Maximum viewer: {value}',
  // 'labels.errorcaptchaToken': 'Please fill in recaptcha',
  // 'labels.eventDetails': 'EVENT DETAILS',
  // 'labels.eventTitle': 'Event Title',
  // 'labels.every3Days': 'Every 3 days',
  // 'labels.exitDraft': 'Exit Draft',
  // 'labels.exitWithoutSaving': 'Exit Without Saving',
  // 'labels.expired': 'Session expired',
  // 'labels.expiredDate': 'Expired Date',
  // 'labels.expiredLink.desc': 'The link has expired. You will need to make a NEW request.',
  // 'labels.expiry': 'Expiry',
  // 'labels.expiryDate.desc': 'Set an expiry date for the template?',
  // 'labels.expiryDate': 'Expiry Date',
  // 'labels.expiryTime': 'Expiry Time',
  // 'labels.expiryUpdate.success': 'Expiry Date has updated successfully to {date}',
  // 'labels.extend': 'Extend',
  // 'labels.faq': 'faq',
  // 'labels.faxNo': 'Fax No.',
  // 'labels.faxNumber': 'Fax Number',
  // 'labels.field': 'Field',
  // 'labels.filter': 'Filter',
  // 'labels.findCountry': 'Find Country',
  // 'labels.finish': 'Finish',
  // 'labels.forgetPassword': 'Forgot password',
  // 'labels.forgetPasswordLink': `<p>Forgot your password ? <a class="text-primary font-weight-bold" href="/bdss/u/forget-password">Forgot Password</a></p>`,
  // 'labels.forgetPwdSuccess': 'We had just sent you a password reset link. Please reset your password before the link expired.',
  // 'labels.getStarted': 'Get started',
  // 'labels.hasVerified': 'has been verified',
  // 'labels.haveAcc': 'Already have an account?',
  // 'labels.haveNnotification': 'You have {amount} new notifications',
  // 'labels.haveNoContact': 'You have no contact',
  // 'labels.haveNoDrafts': 'You have no dratfs',
  // 'labels.haveNoOngoingTask': 'You have no on-going task',
  // 'labels.haveNoPastEvent': 'You have no past event',
  // 'labels.haveNoSigner': 'You have no signers',
  // 'labels.haveNoTask': 'You have no tasks',
  // 'labels.haveNoTemplate': 'You have no template',
  // 'labels.haveNoUser': 'You have no users',
  // 'labels.haveUnsigned': 'You still have some unsigned fields',
  // 'labels.history': 'History',
  // 'labels.hostedBy': 'Hosted By',
  // 'labels.hour': 'hour',
  // 'labels.hours': 'hours',
  // 'labels.identity.appStep1': 'Download Signitory Application from Play Store?',
  // 'labels.identity.appStep2': 'Login and complete the verification process',
  // 'labels.identity.appStep3': 'Wait for verification result as it will take 2-3 working days',
  // 'labels.identity.desc': 'Verify your identity now to unlock the Sign features on Signitory',
  // 'labels.identity.howItWorks': 'How it works?',
  // 'labels.identity.kycStep1Desc': 'Download Signitory Application from Play Store',
  // 'labels.identity.kycStep2Desc': 'Login and complete the verification process',
  // 'labels.identity.kycStep3Desc': 'Wait for verification result as it will take 2-3 working days',
  // 'labels.identity.step': 'Step',
  // 'labels.identityVerification': 'Identity Verification',
  // 'labels.indvUser': 'Individual User',
  // 'labels.info': 'Info',
  // 'labels.initial': 'Initial',
  // 'labels.initializing': 'Initializing',
  // 'labels.inParallel.desc': 'Signer can sign freely without following sequence',
  // 'labels.inParallel': 'In Parallel',
  // 'labels.inSequence.desc': 'Signer must sign based on the arranged sequence',
  // 'labels.inSequence': 'In Sequence',
  // 'labels.inTotal': 'in total',
  // 'labels.invalidAccessKey': 'Invalid access key',
  // 'labels.invalidFileType': 'Invalid file type. We only support {value}',
  // 'labels.invalidKycStatus': 'Invalid Kyc Status',
  // 'labels.invitation': 'Invitation',
  // 'labels.invitationCode.desc': 'Referral by a friend? Add referral code now',
  // 'labels.invitationCode.enter': 'Enter Invitation Code',
  // 'labels.invitationCode.question': 'Do you have an invitation code?',
  // 'labels.invitationCode.success': 'Invitation Code added success',
  // 'labels.invitationCode': 'Invitation Code',
  // 'labels.itemId': 'Item ID',
  // 'labels.jobTitle': 'Job Title',
  // 'labels.joinNewOrganization': 'Join New Organization',
  // 'labels.joinNow': 'Join Now',
  // 'labels.joinOrganization.regDesc': 'Your organization account {email} has successfully registered under organization {name}',
  // 'labels.joinOtherOrganization.desc': 'Manage and view other organization workspace with invitation code.',
  // 'labels.joinOtherOrganization': 'Join other organization',
  // 'labels.joinSuccess': 'Join Successfully',
  // 'labels.knownSigner': 'Known Signer',
  // 'labels.kycStatus.inReview.msg': 'Your KYC is in-review. Stay tuned for more features',
  // 'labels.kycStatus.pending.msg': 'Your KYC is pending. Kindly complete your kyc.',
  // 'labels.kycStatus.rejected.msg': 'Your KYC is rejected. Kindly resubmit your KYC',
  // 'labels.kycStatus.unVerified.msg': 'Please complete your KYC to unlock more features',
  // 'labels.kycStatus': 'KYC Status',
  // 'labels.kycStatusPrefix': 'Identity Verification (KYC) {value}',
  // 'labels.kycVerified': 'KYC verified',
  // 'labels.kycNotVerified': 'KYC not verified',
  // 'labels.lastChangedOn': 'Last changed on',
  // 'labels.lastModified': 'Last modified',
  // 'labels.less': 'Less',
  // 'labels.level': 'Level',
  // 'labels.line1': 'Line 1',
  // 'labels.loading': 'Loading',
  // 'labels.loadMore': 'Load More',
  // 'labels.login.joinToDesc': 'By logging in, your account will bind to organization {orgName}',
  // 'labels.login': 'Login',
  // 'labels.loginAgain': 'Session expired please login again',
  // 'labels.loginLink': `<p>Your account has been activated? <a class="text-primary font-weight-bold" href="/bdss/u/login">Login</a></p>`,
  // 'labels.loginNow': 'Sign in',
  // 'labels.looking.different.plans.desc1': 'Launching an event description service? Planning your next large scale event?',
  // 'labels.looking.different.plans.desc2': 'Or simply need even more advanced tool and support? We have customized plans for that.',
  // 'labels.looking.different.plans': 'Looking for a different plans?',
  // 'labels.manageTag': 'Manage Tags',
  // 'labels.manageTags': 'Manage Tags',
  // 'labels.marketingEmail': 'Marketing Emails',
  // 'labels.marketingEmailDesc': 'We’ll send you related marketing Emails regarding our product and services',
  // 'labels.maxAttempReach': 'maximum attempts reached',
  // 'labels.maxiumOrg': 'Maximum organization reached',
  // 'labels.min': 'minute',
  // 'labels.mins': 'minutes',
  // 'labels.minutes': 'minute/s',
  // 'labels.mobileApp': 'Mobile App',
  // 'labels.month': 'Month',
  // 'labels.months': 'months',
  // 'labels.more': 'More',
  // 'labels.multiSigner.desc': 'The document requires a few signers’ signature',
  // 'labels.multiSigner': 'Multi Signers',
  // 'labels.myOrganization': 'My Organization',
  // 'labels.myProfile': 'My Profile',
  // 'labels.myTask': 'My Task',
  // 'labels.name': 'Name',
  // 'labels.needAnAcc': '<p class="mb-0">You entered an incorrect email. Need an account? <a class="text-purple font-weight-bold" href="/bdss/u/register">Register</a></p>',
  // 'labels.newPwd': 'New Password',
  // 'labels.newUser': 'New User',
  // 'labels.noData': 'No data',
  // 'labels.noRecords': 'No Records',
  // 'labels.noResultEnterNew': "No results matching '{DATA}'. Press <b>enter</b> to create a new one",
  // 'labels.noStamp.msg': 'No stamp has been added. Please contact the admin to proceed',
  // 'labels.not.allowed.to.change': 'Not Allowed To Change',
  // 'labels.noTemplateFound': 'No template found',
  // 'labels.notes': 'Notes',
  // 'labels.notification.deleteAllConfirm': 'Are you sure to delete all the notification?',
  // 'labels.notification.deleteConfirm': 'Are you sure to delete the selected notification?',
  // 'labels.notification': 'Notification',
  // 'labels.notificationDesc': 'We’ll always let you know about all the important changes',
  // 'labels.notificationRemoved': 'Notification removed',
  // 'labels.notifications': 'Notifications',
  // 'labels.notificationSent': 'We have sent a notification to your mobile phone. Please acknowledge your action there.',
  // 'labels.offical.emailAddress': 'Official Email Address',
  // 'labels.officeAddress': 'Office Address',
  // 'labels.officeDetails.desc': 'Provide your organization office address',
  // 'labels.officeDetails': 'Office Details',
  // 'labels.ok': 'Ok',
  // 'labels.oldPwd': 'Old Password',
  // 'labels.onGoing': 'On-going',
  // 'labels.onGoingEvent': 'On-Going event',
  // 'labels.onlySigner.desc': 'The document requires my signature only',
  // 'labels.onlySigner': "I'm The Only Signer",
  // 'labels.organization.create.desc1': 'Adding an organization that you are related to will allow you to create a signature that you can sign on behalf of the organization.',
  // 'labels.organization.create.desc2': 'Your individual account comes with a maximum of three organization profiles.',
  // 'labels.organization.create.desc3': 'You will have an organization automatically assigned to you if an enterprise account adds you as their user or authorized signer.',
  // 'labels.organization.created.desc': 'Your Enterprise Account has been created, do you want to proceed to the User Admin Dashboard to add your user?',
  // 'labels.organization.created': 'Organization Created',
  // 'labels.organization.desc': 'Manage and view your organization workspasce with invitation code.',
  // 'labels.organization.details': 'Organization Details',
  // 'labels.organization.editSuccess': 'Organization edit Successfully',
  // 'labels.organization.info': 'Organization Info',
  // 'labels.organization.initSuccess': 'Organization initiated Successfully',
  // 'labels.organization.name': 'Organization Name',
  // 'labels.organization.registration': 'Organization Registration',
  // 'labels.organization.setSuccess': 'Organization set Successfully',
  // 'labels.organization': 'Organization',
  // 'labels.organizationCode': 'Organization Code',
  // 'labels.organizationEmail': 'Organization Email',
  // 'labels.orgName': 'Org. Name',
  // 'labels.others': 'Others',
  // 'labels.owner.of.the.template': 'Owner Of The Template',
  // 'labels.owner': 'Owner',
  // 'labels.password': 'Password',
  // 'labels.past': 'Past',
  // 'labels.pastEvent': 'Past Event',
  // 'labels.payment': 'PAYMENT',
  // 'labels.personal': 'Personal',
  // 'labels.personalDetails': 'Personal Details',
  // 'labels.phone': 'Phone',
  // 'labels.phoneNo': 'Phone No.',
  // 'labels.phoneNumber': 'Phone Number',
  // 'labels.placeholderConfirmPassword': 'Confirm Password',
  // 'labels.placeholderInSignerList': 'Placeholder already in signer list',
  // 'labels.placeholderLoginEmail': 'Enter your email',
  // 'labels.placeholderLoginName': 'Enter your name',
  // 'labels.placeholderName': 'Placeholder Name',
  // 'labels.placeholderPassword': 'Enter your password',
  // 'labels.plan.addStorageGB': 'Add Storage ({amount} GB)',
  // 'labels.plan.addUser': 'Add User',
  // 'labels.plan.annually': 'Annually',
  // 'labels.plan.auditTrail': 'Audit Trail',
  // 'labels.plan.baseAutoWorkflows': 'Basic Automate Workflows (Sequence, Parallel)',
  // 'labels.plan.blockchainVerifiable': 'Blockchain Verifiable',
  // 'labels.plan.bulkSend': 'Bulk Send',
  // 'labels.plan.chooseRightPlanDesc': `Choose The Plan That's Right For Your Team`,
  // 'labels.plan.comparePlan': 'Compare Plans',
  // 'labels.plan.comparePlans': 'Compare Plans',
  // 'labels.plan.controlPanel': 'Control Panel',
  // 'labels.plan.longTermContracts': 'Are there long-term contracts?',
  // 'labels.plan.maxDocPerEvent': 'Maximum documents per event',
  // 'labels.plan.maxFilePerDoc': 'Maximum File Size Per Document',
  // 'labels.plan.maxSignerPerEvent': 'Maximum signer per event',
  // 'labels.plan.maxTemPerAcc': 'Maximum template per account',
  // 'labels.plan.maxUser': 'Maximun User',
  // 'labels.plan.maxViewerPerEvent': 'Maximum viewer per event',
  // 'labels.plan.minUser': 'Minimun User',
  // 'labels.plan.monitoringNMgmt': 'Monitoring & Management',
  // 'labels.plan.monthly': 'Monthly',
  // 'labels.plan.needAPlanForEveryEmployee': 'Do I need to buy a plan for every employee in my organization?',
  // 'labels.plan.newEventMonthly': 'New event monthly',
  // 'labels.plan.personalizedBranding': 'Personalized Branding',
  // 'labels.plan.plansAvailableDesc': 'How many different Signitory One plans are available?',
  // 'labels.plan.pricingModel': 'Which pricing model do you suggest I choose?',
  // 'labels.plan.reminderNNotification': 'Reminder & Notification',
  // 'labels.plan.seeAllFeatures': 'See all the features you get with any of the plans.',
  // 'labels.plan.seeAllFeaturesDesc': 'See all the features you get with any of the plans.',
  // 'labels.plan.setEventExp': 'Set Event Expiry',
  // 'labels.plan.setEventReminder': 'Set Event Reminder',
  // 'labels.plan.statisticalReport': 'Statistic Report',
  // 'labels.plan.storage': 'Storage',
  // 'labels.plan.storageAllowPerAccMB': 'Storage Allowance Per Account ({amount} MB)',
  // 'labels.plan.supportedFileType': 'Supported file type',
  // 'labels.plan.supportType': 'DOC,DOCX,XLS,XLSX,PPT,PPTX,RTF,ODT,ODS,ODP,TXT',
  // 'labels.plan.tryIndividualPlanDesc': `Try individual plan FREE for 14 days, or get started with Signitory Enterprise`,
  // 'labels.plan': 'Plan',
  // 'labels.planName.with.price.per.unit': '{planName}({currency}{price}/{unit}{measurement})',
  // 'labels.plans.Nevents': '{amount} events',
  // 'labels.plans.NStoragePerAcc': '{amount}{unit} storage per account',
  // 'labels.plans.NtemplatesCreation': '{amount} templates creation',
  // 'labels.plans.setEventExp': 'Set event expiry',
  // 'labels.plans.setEventReminder': 'Set event reminder',
  // 'labels.plans.trialDesc': 'Basic digital contract management. Best for individual use only.',
  // 'labels.plans.upToNUser': 'Up to {amount} user',
  // 'labels.plans': 'Plans',
  // 'labels.plsAssignSigner': 'Please assign signer',
  // 'labels.plsEnterPwd': 'Please enter your password',
  // 'labels.portalLoginPwd': 'Portal Login Password',
  // 'labels.postCode': 'Post Code',
  // 'labels.preview': 'Preview',
  // 'labels.privacyPolicy': 'Privacy Policy',
  // 'labels.purchased': 'Purchased',
  // 'labels.radio': 'Radio',
  // 'labels.readyToJoin': 'Ready to join',
  // 'labels.reason': 'reason',
  // 'labels.recordInBC.desc': 'RM3 per event',
  // 'labels.recordInBC': 'Record in Blockchain',
  // 'labels.referral.cannotModified': 'Referral code cannot be modified once submitted',
  // 'labels.referral.kycUnlock': 'Please complete your KYC first in order to proceed further',
  // 'labels.referral.myCode': 'My Referral Code',
  // 'labels.referral.program.desc.1': 'Share your referral code and',
  // 'labels.referral.program.desc.2': 'Get 20% Bonus Credit',
  // 'labels.referral.program.desc.3': 'of your friend subscription fee where you can use it on your next payment',
  // 'labels.referral.program': 'Referral Program',
  // 'labels.register.joinToDesc': 'By registering, your account will bind to organization {orgName}',
  // 'labels.register': 'Register',
  // 'labels.registerNow': 'Register Now',
  // 'labels.registrationNo': 'Registration No.',
  // 'labels.registrationNumber': 'Registration Number',
  // 'labels.regNo': 'Registration No.',
  // 'labels.rejectContract.confirm': 'Are you sure to reject this contract?',
  // 'labels.rejectContract.success': 'Contract has been rejected successfully',
  // 'labels.rejectContract': 'Reject Contract',
  // 'labels.rejected': 'Rejected',
  // 'labels.rejectReason': 'Reject Reason',
  // 'labels.rejectSigning': 'Reject Signing',
  // 'labels.representing': 'Representing',
  // 'labels.rememberMe': 'Remember me',
  // 'labels.reminder': 'Reminder',
  // 'labels.removeContact': 'Remove Contact',
  // 'labels.removeUser': 'Remove User',
  // 'labels.requestDate': 'Request Date',
  // 'labels.resendEmail': 'Resend email',
  // 'labels.resetPwdSuccess': 'Reset password successfully!',
  // 'labels.retry': 'Retry ',
  // 'labels.returnTo': 'Return to ',
  // 'labels.role': 'Role',
  // 'labels.rowsPerPage': 'Rows per page',
  // 'labels.save': 'Save',
  // 'labels.saveChanges': 'Save Changes',
  // 'labels.saveNClose': 'Save and Close',
  // 'labels.saveTemplate': 'Save Template',
  // 'labels.searchForUsers': 'Search for users',
  // 'labels.searchFromYourContact': 'Search From Your Contact',
  // 'labels.sec': 'second',
  // 'labels.seconds': 'second/s',
  // 'labels.secs': 'seconds',
  // 'labels.security': 'Security',
  // 'labels.securityDesc': 'Security recommendations to help you keep your account secure',
  // 'labels.securityQuestion': 'Security question',
  // 'labels.securityQuestionDesc': 'Please fill in the last 6 digit of your identification number',
  // 'labels.select.cancellationReason': 'Select cancellation reason',
  // 'labels.select': 'Select',
  // 'labels.selectCompanyStamp': 'Select Company Stamp',
  // 'labels.selectedDoc': 'Selected {amount} contact',
  // 'labels.selectOrganization': 'Select organization',
  // 'labels.selectSignatureProfile.desc': 'By selecting a signature profile to sign, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on envelopes, including legally binding contracts - just the same as a pen-and-paper signature or initial.',
  // 'labels.selectSignatureProfile': 'Select Signature Profile',
  // 'labels.selectTemplate': 'Select A Template',
  // 'labels.sendAgain': 'Send again',
  // 'labels.sendReminder.success': 'Reminder has sent to {email}',
  // 'labels.sendReminder': 'Send Reminder',
  // 'labels.sentActivationDesc': 'We have sent an email to ',
  // 'labels.sentActivationDesc2': 'You need to verify your email to continue. If you have not received the verification email, please check your “Spam” or “Bulk Email” folder.',
  // 'labels.session': 'Session',
  // 'labels.setAsAuthorizedSigner': 'Set as authorized signer',
  // 'labels.settingsNReview': 'Settings & Review',
  // 'labels.shared': 'Shared',
  // 'labels.sigName': 'Signature Name',
  // 'labels.signature': 'Signature',
  // 'labels.signatureName': 'Signature Name',
  // 'labels.signDocument': 'Sign Document',
  // 'labels.signed': 'Signed',
  // 'labels.signer': 'Signer',
  // 'labels.signerEmail': 'Signer Email',
  // 'labels.signerMgmt': 'Signer Management',
  // 'labels.signerName': 'Signer Name',
  // 'labels.signerType': 'Signer Type',
  // 'labels.signingField': 'Signing Field',
  // 'labels.signingInfo.desc': 'Add a known signer or give a placeholder for the moment for unknown signer.',
  // 'labels.signingInfo.fromTemplate.desc': 'Replace any unknown signer/placeholder and assign a signer for your document.',
  // 'labels.signingOrder': 'Signing Order',
  // 'labels.signingType': 'Signing Type',
  // 'labels.stamp': 'Stamp',
  // 'labels.stampName': 'Stamp Name',
  // 'labels.startNow': 'Start Now',
  // 'labels.stateRegion': 'State / Region',
  // 'labels.status': 'Status',
  // 'labels.step': 'STEP',
  // 'labels.submit': 'Submit',
  // 'labels.subscription': 'Subscription',
  // 'labels.success': 'Success',
  // 'labels.support': 'Support',
  // 'labels.tag': 'TAG',
  // 'labels.tags': 'Tags',
  // 'labels.template': 'Template',
  // 'labels.templateAddSuccess': 'Template added successfully!',
  // 'labels.templateData': 'Template Data',
  // 'labels.templateDetails': 'TEMPLATE DETAILS',
  // 'labels.templateId': 'Template ID',
  // 'labels.templateSetting': 'Template Setting',
  // 'labels.templateTitle': 'Template Title',
  // 'labels.termOfUse': 'Term of Use',
  // 'labels.termsAndCond': 'Terms and Conditions',
  // 'labels.termsAndCondition': 'By clicking Create, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on envelopes, including legally binding contracts - just the same as a pen-and-paper signature or initial.',
  // 'labels.text': 'Text',
  // 'labels.thumbnails': 'Thumbnails',
  // 'labels.timeLeft': '{label} left',
  // 'labels.timestamp': 'Timestamp',
  // 'labels.title': 'Title',
  // 'labels.today': 'Today',
  // 'labels.transactionHash': 'Transaaction Hash',
  // 'labels.twoFAAuth': 'Two-factor Authentication',
  // 'labels.unableToProceed': 'Unable to proceed',
  // 'labels.unAuthorize': 'Unauthorize',
  // 'labels.unAuthorizedUser': 'Unauthorized User',
  // 'labels.unAuthorizeSigner.success': 'Selected signer has been un-authorize successfully',
  // 'labels.unAuthorizeUser': 'Unauthorize User',
  // 'labels.undefined': 'Undefined',
  // 'labels.unknownSigner.desc': 'Use this handy feature for simple documents that you will use again and again. Give your future signer a placeholder name (like Client, Buyer, Finance Manager, Beneficiary etc.) and duplicate this template next time to assign different signers.',
  // 'labels.unknownSigner': 'Unknown Signer',
  // 'labels.updateViewerSuccess': 'Viewer updated successfully',
  // 'labels.upload.newCompCert.success': 'New Company Certificate has been updated successfully.',
  // 'labels.upload': 'Upload',
  // 'labels.uploadDoc': 'Upload / drag a document',
  // 'labels.uploadDocument': 'Upload Document',
  // 'labels.uploadFail': 'Upload Failed',
  // 'labels.uploading': 'Uploading',
  // 'labels.uploadingDoc': 'Uploading Document',
  // 'labels.uploadNewDoc': 'Upload a New document',
  // 'labels.uploadValidation': 'Please provide signature',
  // 'labels.use': 'Use',
  // 'labels.user': 'User',
  // 'labels.userEmail': 'Email',
  // 'labels.userMgmt': 'User Management',
  // 'labels.username': 'Username',
  // 'labels.userTemplate': 'Use Template',
  // 'labels.verificationStatus': 'Verification Status',
  // 'labels.verifyQuesAgain': 'Please re-enter the security code again in ',
  // 'labels.view': 'View',
  // 'labels.viewAll': 'View All',
  // 'labels.viewDetails': 'View Details',
  // 'labels.viewDoc': 'View Document',
  // 'labels.viewer': 'viewer',
  // 'labels.Viewer': 'VIEWER',
  // 'labels.viewingSecurity.desc': 'Require viewer to key in PIN or Login',
  // 'labels.viewingSecurity': 'Viewing Security',
  // 'labels.viewReport': 'View Report',
  // 'labels.viewTransaction.history': 'View transaction history',
  // 'labels.warning': 'Warning',
  // 'labels.waitAppLogin': 'Please verify with your mobile phone',
  // 'labels.website': 'Website',
  // 'labels.wrongPwd': 'Wrong Password',
  // 'labels.year': 'year',
  // 'labels.years': 'years',
  // 'labels.unauthorizeSignerWarning': 'Un-authorising the selected signers might cause all the on-going event tied or created by this person to be invalid and considered a PAST event.',
  // 'labels.unauthorizeSignerConfirmationTitle': 'Un-authorise Signer Confirmation',
  // 'labels.authorizeSignerConfirmationTitle': 'Authorise Signer Confirmation',
  // 'labels.ongoingEvents': ' ( {amount} on-going events )',
};
