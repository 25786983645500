import AppStorage from '@/plugins/storage/index.js';
import { isUndefinedNullOrEmpty } from '@/util/tools';

const order = {
  state: {
    orderHistory: [],
    orderPending: [],
    deliveryFee: {
      // if cart total amt is lesser than "amt", then incur delivery fee of "fee"
      amt: null, // minimum amount to be entitled for delivery fee waiver
      deliveryFee: null,
    }
  },
  getters: {
    getOrderHistory: state => {
      if (!isUndefinedNullOrEmpty(state.orderHistory)) {
        return state.orderHistory;
      } else if (!isUndefinedNullOrEmpty(AppStorage.local.get('order-hist'))) {
        return JSON.parse(AppStorage.local.get('order-hist'));
      } else {
        return [];
      }
    },
    getOrderPending: state => {
      if (!isUndefinedNullOrEmpty(state.orderPending)) {
        return state.orderPending;
      } else if (!isUndefinedNullOrEmpty(AppStorage.local.get('order-pend'))) {
        return JSON.parse(AppStorage.local.get('order-pend'));
      } else {
        return [];
      }
    },
    getDeliveryFee: state => {
      if (!isUndefinedNullOrEmpty(state.deliveryFee?.amt) && !isUndefinedNullOrEmpty(state.deliveryFee?.deliveryFee)) {
        return state.deliveryFee;
      } else if (!isUndefinedNullOrEmpty(AppStorage.local.get('dFee'))) {
        return JSON.parse(AppStorage.local.get('dFee'));
      } else {
        return {};
      }
    },
  },
  mutations: {
    CLEAR_ORDER_HISTORY(state) {
      state.orderHistory = [];
      AppStorage.local.remove('order-hist');
    },
    SET_ORDER_HISTORY(state, payload) {
      state.orderHistory = payload;
      AppStorage.local.set('order-hist', JSON.stringify(payload));
    },
    SET_ORDER_PENDING(state, payload) {
      state.orderPending = payload;
      AppStorage.local.set('order-pend', JSON.stringify(payload));
    },
    SET_DELIVERY_FEE(state, payload) {
      state.deliveryFee = payload;
      AppStorage.local.set('dFee', JSON.stringify(payload));
    },
    CLEAR_DELIVERY_FEE(state) {
      state.deliveryFee = {
        amt: null,
        deliveryFee: null,
      };
      AppStorage.local.remove('dFee');
    },
  },
  actions: {
    setOrderHistory({ commit, getters }, payload) {
      return new Promise(resolve => {
        let orderHistory = getters.getOrderHistory;
        if (orderHistory.length >= 5) {
          orderHistory.shift();
        }
        orderHistory.push(payload);

        commit('SET_ORDER_HISTORY', orderHistory);
        resolve();
      });
    },
    updateOrderHistory({ commit, getters }, payload) {
      let orderHistory = getters.getOrderHistory;
      const index = orderHistory.findIndex(item => item.orderId == payload.orderId);
      orderHistory[index] = payload;

      commit('SET_ORDER_HISTORY', orderHistory);
    },
    updateOrderHistoryCancel({ commit, getters }, payload /* orderId */) {
      let orderHistory = getters.getOrderHistory;
      for (let index in orderHistory) {
        if (orderHistory[index].orderId == payload) {
          orderHistory[index].cancelled = 1;
          break;
        }
      }
      commit('SET_ORDER_HISTORY', orderHistory);
    },
    setOrderPending({ commit, getters }, payload) {
      let orderPending = getters.getOrderPending;
      if (orderPending.length >= 5) {
        orderPending.shift();
      }
      orderPending.push(payload);
      commit('SET_ORDER_PENDING', orderPending);
    },
    updateOrderPending({ commit, getters }, payload) {
      let orderPending = getters.getOrderPending;
      const index = orderPending.findIndex(item => item.orderId == payload.orderId);
      orderPending[index] = payload;
      commit('SET_ORDER_PENDING', orderPending);
    },
  },
};
export default order;
