// Globals
import { authApi } from '@/api/auth';
import AppStorage from '@/plugins/storage/index.js';
import router from '@/router';
import { sha256 } from 'js-sha256';
import jwt from 'jsonwebtoken';
import { TOKEN_TYPE } from '@/common/enum/common';

const user = {
  state: {
    dark: false,
    drawer: {
      image: 0,
      gradient: 0,
      mini: false,
    },
    gradients: [
      'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
      'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
      'rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)',
    ],
    images: [
      'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
      'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-2.jpg',
      'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-3.jpg',
      'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-4.jpg',
    ],
    notifications: [],
    rtl: false,
    token: null, // Guest token
    userToken: null,
    iat: null, // time in seconds
    expireTime: null, // time in seconds
    tokenType: null,
    // orgId: null,
    userId: null,
    orderUserId: null, // will get this after /place/order, use this to get order seq (If user is guest). Clear this after payment success or failed page
    myRefToken: null, // my referral token. (I share to others)
    otRefToken: null, // others' referral token (Others share to me)
    userName: '',
    userDisplayName: '',
    mobileNo: '',
    email: '',
    postcode: '',
    address: '',
    loginModalShow: false,
  },
  getters: {
    dark: (state, getters) => {
      return state.dark || getters.gradient.indexOf('255, 255, 255') === -1;
    },
    gradient: state => {
      return state.gradients[state.drawer.gradient];
    },
    image: state => {
      return state.drawer.image === '' ? state.drawer.image : state.images[state.drawer.image];
    },
    getIat: state => {
      return state.iat ? state.iat : AppStorage.local.get('iat');
    },
    getExpireTime: state => {
      if (state.expireTime) {
        return state.expireTime;
      } else if (AppStorage.local.get('expireTime')) {
        return Number(AppStorage.local.get('expireTime'));
      } else {
        return null;
      }
    },
    getTokenType: state => {
      return state.tokenType ? state.tokenType : AppStorage.local.get('tokenType');
    },
    getAddress: state => {
      return state.address ? state.address : AppStorage.local.get('address');
    },
    getPostcode: state => {
      return state.postcode ? state.postcode : AppStorage.local.get('postcode');
    },
    getToken: state => {
      return state.token ? state.token : AppStorage.local.get('token');
    },
    getUserToken: state => {
      return state.userToken ? state.userToken : AppStorage.local.get('userToken');
    },
    getUserDisplayName: state => {
      return state.userDisplayName ? state.userDisplayName : AppStorage.local.get('userDisplayName');
    },
    getMobileNo: state => {
      return state.mobileNo ? state.mobileNo : AppStorage.local.get('mobileNo');
    },
    getEmail: state => {
      return state.email ? state.email : AppStorage.local.get('email');
    },
    getOrderUserId: state => {
      return state.orderUserId ? state.orderUserId : AppStorage.local.get('orderUserId');
    },
    getMyRefToken: state => {
      return state.myRefToken ? state.myRefToken : AppStorage.local.get('myRefToken');
    },
    getOtRefToken: state => {
      // Others' referral token
      return state.otRefToken ? state.otRefToken : AppStorage.local.get('otRefToken');
    },
  },
  mutations: {
    SET_GRADIENT(state, payload) {
      state.drawer.gradient = payload;
    },
    SET_IMAGE(state, payload) {
      state.drawer.image = payload;
    },
    SET_GUEST_TOKEN(state, payload) {
      state.token = payload;
      payload ? AppStorage.local.set('token', payload) : AppStorage.local.remove('token');
    },
    SET_USER_TOKEN(state, payload) {
      state.userToken = payload;
      payload ? AppStorage.local.set('userToken', payload) : AppStorage.local.remove('userToken');
    },
    SET_JWT_IAT(state, payload) {
      state.iat = payload;
      payload ? AppStorage.local.set('iat', payload) : AppStorage.local.remove('iat');
    },
    SET_EXPIRETIME(state, payload) {
      state.expireTime = payload;
      payload ? AppStorage.local.set('expireTime', payload) : AppStorage.local.remove('expireTime');
    },
    SET_TOKEN_TYPE(state, payload) {
      state.tokenType = payload;
      payload ? AppStorage.local.set('tokenType', payload) : AppStorage.local.remove('tokenType');
    },
    // SET_ORG_ID(state, payload) {
    //   state.orgId = payload;
    //   payload ? AppStorage.local.set('orgId', payload) : AppStorage.local.remove('orgId');
    // },
    SET_USER_ID(state, payload) {
      state.userId = payload;
      payload ? AppStorage.local.set('userId', payload) : AppStorage.local.remove('userId');
    },
    SET_USER_NAME(state, payload) {
      state.userName = payload;
      payload ? AppStorage.local.set('userName', payload) : AppStorage.local.remove('userName');
    },
    SET_USER_DISPLAY_NAME(state, payload) {
      state.userDisplayName = payload;
      payload ? AppStorage.local.set('userDisplayName', payload) : AppStorage.local.remove('userDisplayName');
    },
    SET_USER_MOBILE_NO(state, payload) {
      state.mobileNo = payload;
      payload ? AppStorage.local.set('mobileNo', payload) : AppStorage.local.remove('mobileNo');
    },
    SET_EMAIL(state, payload) {
      state.email = payload;
      payload ? AppStorage.local.set('email', payload) : AppStorage.local.remove('email');
    },
    SET_ORDER_USER_ID(state, payload) {
      state.orderUserId = payload;
      payload ? AppStorage.local.set('orderUserId', payload) : AppStorage.local.remove('orderUserId');
    },
    SET_MY_REF_TOKEN(state, payload) {
      state.myRefToken = payload;
      payload ? AppStorage.local.set('myRefToken', payload) : AppStorage.local.remove('myRefToken');
    },
    SET_OT_REF_TOKEN(state, payload) {
      state.otRefToken = payload;
      payload ? AppStorage.local.set('otRefToken', payload) : AppStorage.local.remove('otRefToken');
    },
    SET_ADDRESS(state, payload) {
      state.address = payload;
      payload ? AppStorage.local.set('address', payload) : AppStorage.local.remove('address');
    },
    SET_POSTCODE(state, payload) {
      state.postcode = payload;
      payload ? AppStorage.local.set('postcode', payload) : AppStorage.local.remove('postcode');
    },
    SET_LOGIN_MODAL_SHOW(state, payload) {
      state.loginModalShow = payload;
    },
  },
  actions: {
    login_act({ commit, dispatch }, data) {
      data = {
        username: Date.now().toString(),
      };

      data.password = sha256(data.username);
      // data.password = sha256(data.password);
      return authApi
        .guestLogin(data)
        .then(async response => {
          // let data = response.data;
          return response;
        })
        .catch(error => {
          throw error;
        });
    },
    userLogin_act({ commit, dispatch, getters }, data) {
      data = {
        username: getters.getMobileNo,
        email: getters.getEmail,
        name: getters.getUserDisplayName,
      };

      // data.password = sha256(data.password);
      return authApi
        .userLogin(data)
        .then(async response => {
          // let data = response.data;
          return response;
        })
        .catch(error => {
          throw error;
        });
    },
    // Guest login
    processLoginSuccess_act({ commit }, data) {
      console.log('Setting guest token');
      const decodedJwt = jwt.decode(data.token);

      commit('SET_GUEST_TOKEN', data.token);
      commit('SET_TOKEN_TYPE', TOKEN_TYPE.GUEST);
      commit('SET_JWT_IAT', decodedJwt.iat);
      commit('SET_EXPIRETIME', decodedJwt.exp);
      // commit('SET_ORG_ID', data.orgId);
      commit('SET_USER_ID', data.userId);
      commit('SET_USER_NAME', data.name);
      return data;
    },
    // user login
    processUserLoginSuccess_act({ commit }, data) {
      console.log('Setting user token');
      const decodedJwt = jwt.decode(data.token);

      commit('SET_USER_TOKEN', data.token);
      commit('SET_TOKEN_TYPE', TOKEN_TYPE.USER);
      commit('SET_JWT_IAT', decodedJwt.iat);
      commit('SET_EXPIRETIME', decodedJwt.exp);

      commit('SET_USER_ID', data.userId);
      commit('SET_USER_MOBILE_NO', data.login);
      commit('SET_USER_NAME', data.name);
      commit('SET_USER_DISPLAY_NAME', data.name);
      commit('SET_EMAIL', data.email);
      return data;
    },
    logout_act({ commit }) {
      return new Promise(resolve => {
        commit('SET_GUEST_TOKEN', '');
        commit('SET_EXPIRETIME', '');
        // commit('SET_ORG_ID', '');
        commit('SET_USER_ID', '');
        commit('SET_USER_NAME', '');
        commit('SET_USER_DISPLAY_NAME', '');
        commit('SET_USER_MOBILE_NO', '');
        commit('CLEAR_CART');
        // AppStorage.local.remove('isLogin');
        // AppStorage.session.remove('forceQ');
        // AppStorage.session.remove('quickReg');
        // AppStorage.session.remove('selectedCountry');
        // router.replace({ name: 'Login' }).catch();
        return resolve();
      });
    },
  },
};
export default user;
