const Layout = () => import('@/layouts/default/Index');
const MainLayout = () => import('@/layouts/main/index');

const Login = () => import('@/views/Login');

const Cart = () => import('@/views/Cart');
const Main = () => import('@/views/Main');
const Menu = () => import('@/views/Menu');
const MenuListAll = () => import('@/views/Menu/ListAll');
const MenuSetItemWrap = () => import('@/views/Menu/SetItemWrap');
const MenuALaCarteItem = () => import('@/views/Menu/ALaCarteItem');
const MenuPromotion = () => import('@/views/Menu/PromotionList');
// const Payment = () => import('@/views/Payment');
const Order = () => import('@/views/Order');
const OrderCheckout = () => import('@/views/Order/checkout');
const OrderPayment = () => import('@/views/Order/payment');
const OrderDetails = () => import('@/views/OrderDetails');
const OrderHistory = () => import('@/views/OrderHistory');
const ReferrerTransition = () => import('@/views/Referral/Referrer/components/transition');
// const Test = () => import('@/views/Test');
// const Orders = () => import('@/views/Orders');
// const Stocks = () => import('@/views/Stocks');

export const routes = [
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: Login,
  // },
  {
    path: '',
    name: 'MainLayout',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'Main',
        component: Main,
      }
    ]
  },
  {
    path: '',
    component: Layout,
    redirect: { name: 'MenuAll' },
    children: [
      {
        path: 'cart',
        name: 'Cart',
        component: Cart,
        meta: { requireAuth: true },
      },
      {
        path: 'referral',
        name: 'ReferrerTransition',
        component: ReferrerTransition,
        meta: { requireAuth: true },
      },
      // {
      //   path: 'payment',
      //   name: 'Payment',
      //   component: Payment,
      //   meta: { requireAuth: true, },
      // },
      {
        path: 'order',
        name: 'Order',
        component: Order,
        meta: { requireAuth: true, },
        children: [
          {
            path: 'checkout/:channel(takeaway|pickup|delivery)',
            name: 'OrderCheckout',
            component: OrderCheckout,
            meta: { requireAuth: true, },
          },
          {
            path: 'payment',
            name: 'OrderPayment',
            component: OrderPayment,
            meta: { requireAuth: true, },
          },
        ],
      },
      {
        path: 'order-details/:orderId',
        name: 'OrderDetails',
        component: OrderDetails,
        meta: { requireAuth: true, },
      },
      {
        path: 'order-history',
        name: 'OrderHistory',
        component: OrderHistory,
        meta: { requireAuth: true, },
        children: [
          {
            path: 'list',
            name: 'OrderHistoryList',
            component: OrderHistory,
            meta: { requireAuth: true, },
          },
          {
            path: 'details/:orderId',
            name: 'OrderHistoryDetails',
            component: OrderHistory,
            meta: { requireAuth: true, },
          },
        ],
      },
      // {
      //   path: 'test',
      //   name: 'Test',
      //   component: Test,
      // }
      // {
      //   path: 'orders',
      //   name: 'Orders',
      //   component: Orders,
      //   meta: { requireAuth: true, },
      // },
    ],
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu,
    children: [
      {
        path: 'all',
        name: 'MenuAll',
        component: MenuListAll,
        meta: { requireAuth: true, },
        props: true,
      },
      {
        path: 'set/:menuId',
        name: 'MenuSetItem',
        component: MenuSetItemWrap,
        meta: { requireAuth: true, },
        props: true,
      },
      {
        path: 'alacarte/:menuId',
        name: 'MenuALaCarteItem',
        component: MenuALaCarteItem,
        meta: { requireAuth: true, },
      },
      {
        path: 'promotion/:menuId?',
        name: 'MenuPromotion',
        component: MenuPromotion,
        meta: { requireAuth: true, },
      },
    ],
  },
];
