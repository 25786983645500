// import { menuApi, menuItemApi, menuItemOptApi, menuItemOptGrpSubApi } from '@/api/menu';
import AppStorage from '@/plugins/storage/index.js';
import { isUndefinedNullOrEmpty } from '@/util/tools.js';

function itemsMatched(data, list) {
  if (data.length != list.length) return false;

  let sortedData = data.sort();
  let sortedList = list.sort();
  for (let i = 0; i < sortedList.length; i++) {
    if (sortedList[i].itemId != sortedData[i].itemId) return false;
  }
  return true;
}

const cart = {
  state: {
    cart: [],
    focCart: [],
    placedCart: [],
    placedFocCart: [],
  },
  getters: {
    getCartItems: state => {
      if (!isUndefinedNullOrEmpty(state.cart)) return state.cart;
      else if (!isUndefinedNullOrEmpty(AppStorage.local.get('cart'))) {
        return JSON.parse(AppStorage.local.get('cart'));
      } else return [];
    },
    getCartItemsCount: state => {
      if (!isUndefinedNullOrEmpty(state.cart)) return state.cart.length;
      else if (!isUndefinedNullOrEmpty(AppStorage.local.get('cart'))) {
        const cart = JSON.parse(AppStorage.local.get('cart'));
        return cart.length > 0 ? cart.length : 0;
      } else return 0;
    },
    getFocCartItems: state => {
      if (!isUndefinedNullOrEmpty(state.focCart)) return state.focCart;
      else if (!isUndefinedNullOrEmpty(AppStorage.local.get('focCart'))) {
        return JSON.parse(AppStorage.local.get('focCart'));
      } else return [];
    },

    getPlacedCartItems: state => {
      if (!isUndefinedNullOrEmpty(state.placedCart)) return state.placedCart;
      else if (!isUndefinedNullOrEmpty(AppStorage.local.get('placedCart'))) {
        return JSON.parse(AppStorage.local.get('placedCart'));
      } else return [];
    },
    getPlacedFocCartItems: state => {
      if (!isUndefinedNullOrEmpty(state.placedFocCart)) return state.placedFocCart;
      else if (!isUndefinedNullOrEmpty(AppStorage.local.get('placedFocCart'))) {
        return JSON.parse(AppStorage.local.get('placedFocCart'));
      } else return [];
    },
    getCartTotalPrice: (state, getters) => {
      let total = 0;
      for (let item of getters.getCartItems) {
        total = total + item.qty * item.price;
      }
      return total;
    },
  },
  mutations: {
    ADD_TO_CART(state, payload) {
      state.cart = payload;
      AppStorage.local.set('cart', JSON.stringify(payload));
    },
    UPDATE_WHOLE_CART(state, payload) {
      state.cart = payload;
      AppStorage.local.set('cart', JSON.stringify(payload));
    },
    REMOVE_FROM_CART(state, payload) {
      // state.cart = payload;
      state.cart.splice(payload.index, 1);
      AppStorage.local.set('cart', JSON.stringify(state.cart));
    },
    CLEAR_CART(state) {
      state.cart = [];
      AppStorage.local.remove('cart');
    },
    ADD_TO_FOC_CART(state, payload) {
      state.focCart = payload;
      AppStorage.local.set('focCart', JSON.stringify(payload));
    },
    CLEAR_FOC_CART(state) {
      state.focCart = [];
      AppStorage.local.remove('focCart');
    },

    SET_PLACED_CART(state, payload) {
      state.placedCart = payload;
      AppStorage.local.set('placedCart', JSON.stringify(payload));
    },
    SET_PLACED_FOC_CART(state, payload) {
      state.placedFocCart = payload;
      AppStorage.local.set('placedFocCart', JSON.stringify(payload));
    },
    CLEAR_PLACED_CART(state) {
      state.placedCart = [];
      AppStorage.local.remove('placedCart');
    },
    CLEAR_PLACED_FOC_CART(state) {
      state.placedFocCart = [];
      AppStorage.local.remove('placedFocCart');
    },
  },
  actions: {
    addToCart({ commit, getters, dispatch }, data) {
      let cart = getters.getCartItems;

      // Handle duplicate item logic
      let pushed = false;

      for (let i = 0; i < cart.length; i++) {
        let current = cart[i];
        if (current.menuItemId == data.menuItemId) {
          if (isUndefinedNullOrEmpty(current.items) && isUndefinedNullOrEmpty(data.items)) {
            cart[i].qty += data.qty;
            pushed = true;
            break;
          }
          if (!isUndefinedNullOrEmpty(current.items) && !isUndefinedNullOrEmpty(data.items)) {
            if (itemsMatched(data.items, current.items)) {
              cart[i].qty += data.qty;
              pushed = true;
              break;
            }
          }
        }
      }
      if (!pushed) cart.push(data);
      commit('ADD_TO_CART', cart);
      if (!isUndefinedNullOrEmpty(getters.getPlacedCartItems)) dispatch('clearPlacedCart');
    },
    addArrToCart({ commit, getters, dispatch }, data) {
      let cart = getters.getCartItems;

      // Handle duplicate item logic
      if (cart.length > 0) {
        for (let item of data) {
          for (let i = 0; i < cart.length; i++) {
            let current = cart[i];
            if (current.menuItemId == item.menuItemId) {
              if (isUndefinedNullOrEmpty(current.items) && isUndefinedNullOrEmpty(item.items)) {
                cart[i].qty += data.qty;
                break;
              }
              if (!isUndefinedNullOrEmpty(current.items) && !isUndefinedNullOrEmpty(item.items)) {
                if (itemsMatched(item.items, current.items)) {
                  cart[i].qty += item.qty;
                  break;
                }
              }
            }
            if (i == cart.length - 1) {
              cart.push(item);
              break; // Break here because after push to cart, length += 1
            }
          }
        }
        commit('ADD_TO_CART', cart);
      } else {
        commit('ADD_TO_CART', data);
      }
      // console.log('cart after', cart);
      if (!isUndefinedNullOrEmpty(getters.getPlacedCartItems)) dispatch('clearPlacedCart');
    },
    updateWholeCart({ commit, getters, dispatch }, data) {
      commit('UPDATE_WHOLE_CART', data);
      if (!isUndefinedNullOrEmpty(getters.getPlacedCartItems)) dispatch('clearPlacedCart');
    },
    removeFromCart({ commit, state }, data) {
      commit('REMOVE_FROM_CART', data);
    },
    clearCart({ commit, state }) {
      commit('CLEAR_CART');
    },
    addToFocCart({ commit, state, getters, dispatch }, data) {
      commit('ADD_TO_FOC_CART', data);
      if (!isUndefinedNullOrEmpty(getters.getPlacedFocCartItems)) dispatch('clearPlacedFocCart');
    },
    clearFocCart({ commit, state }) {
      commit('CLEAR_FOC_CART');
    },

    setPlacedCart({ commit, state }, data) {
      commit('SET_PLACED_CART', data);
    },
    setPlacedFocCart({ commit, state }, data) {
      commit('SET_PLACED_FOC_CART', data);
    },
    clearPlacedCart({ commit, state }) {
      commit('CLEAR_PLACED_CART');
    },
    clearPlacedFocCart({ commit, state }) {
      commit('CLEAR_PLACED_FOC_CART');
    },
    // fetchMenuList({ commit }, data) {
    //   return menuApi.list(data)
    //     .then(response => {
    //       const data = response.data;
    //       commit('SET_MENU_LIST', data);
    //       return response;
    //     });
    // },
  },
};
export default cart;
