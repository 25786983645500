import i18n from '@/translations';
import { MENU_TYPE } from '@/common/enum/menu';
import { isUndefinedNullOrEmpty } from '@/util/tools';
import AppStorage from '@/plugins/storage/index.js';
import { orderApi } from '@/api/order';

const app = {
  state: {
    drawer: false,
    drawerImage: false,
    drawerTitle: '',
    mini: true,
    items: [
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: i18n.t('bar.main.lbl.aboutUs'),
        icon: '',
        href: '#about-us',
      },
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: i18n.t('bar.main.lbl.ourIngredients'),
        icon: '',
        href: '#our-ingredients',
      },
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: i18n.t('bar.main.lbl.ourMenu'),
        icon: '',
        href: '#our-menu',
      },
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: i18n.t('bar.main.lbl.ourCoffee'),
        icon: '',
        href: '#our-coffee',
      },
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: i18n.t('bar.main.lbl.location'),
        icon: '',
        href: '#location',
      },
    ],
    currentLang: {},
    pageSizeOpts: [2, 5, 10, 15, 20, 50, -1],
    paginationOpts: {
      page: 1,
      pageSize: 10,
    },
    dateFormat: 'YYYY-MM-DD',
    timeFormat: 'h:mma',
    defaultPageAfterLogin: 'MenuAll',
    badges: {
      orders: 0,
    },
  },
  getters: {
    getCurrentLang() {
      let lang = AppStorage.local.get('lang');
      if (!isUndefinedNullOrEmpty(lang)) {
        return JSON.parse(lang);
      } else {
        lang = JSON.stringify({ code: 'en_US', name: 'English' });
        AppStorage.local.set('lang', lang);
        return lang;
      }
    },
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_DRAWER_IMG(state, payload) {
      state.drawerImage = payload;
    },
    SET_MINI(state, payload) {
      state.mini = payload;
    },
    SET_DRAWER_TITLE(state, payload) {
      state.drawerTitle = payload;
    },
  },
  actions: {
  },
};

export default app;
