<template>
  <v-container>
    <v-row align="center">
      <v-col v-for="(link, i) in links" :key="i" class="text-center" cols="6" md="auto">
        <a
          :href="link.href"
          class="text-decoration-none text-uppercase text-caption font-weight-regular"
          rel="noopener"
          target="_blank"
          v-text="link.text"
        />
      </v-col>

      <v-spacer class="hidden-sm-and-down" />

      <v-col cols="12" md="auto">
        <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
          &copy; {{ new Date().getFullYear() }}, Made by ...
          <!-- <v-icon>mdi-vuetify</v-icon>
          <a
            href="https://vuetifyjs.com/en/about/meet-the-team/#company"
            class="text-decoration-none"
          >Vuetify</a> -->
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Links',

  data: () => ({
    links: [
      {
        href: '#',
        text: 'Link 1',
      },
      {
        href: '#',
        text: 'Link 2',
      },
      {
        href: '#',
        text: 'Link 3',
      },
      {
        href: '#',
        text: 'Link 4',
      },
      // {
      //   href: 'https://vuetifyjs.com/',
      //   text: 'Vuetify Docs',
      // },
      // {
      //   href: '#',
      //   text: 'About Us',
      // },
      // {
      //   href: '#',
      //   text: 'Blog',
      // },
      // {
      //   href: '#',
      //   text: 'Licenses',
      // },
    ],
  }),
};
</script>

<style lang="sass" scoped>
a
  color: inherit !important
</style>
