<template>
  <ValidationProvider ref="validate" mode="aggressive" :vid="vid" :name="name" :rules="rules" v-slot="{ errors }">
    <label
      v-if="showLabel"
      class="font-12 font-weight-bold text-dark-gray mt-3 mb-2"
      :class="{ 'text-danger': 0 !== errors.length }"
      >{{ label }}</label
    >
    <v-text-field
      v-model="computedValue"
      class="input"
      clear-icon="mdi-close-circle"
      :class="className"
      :type="type"
      :label="label"
      :placeholder="placeholder"
      :outlined="outlined"
      :filled="filled"
      :full-width="fullWidth"
      :append-icon="appendIcon"
      :append-outer-icon="appendOuterIcon"
      :prepend-inner-icon="prependInnerIcon"
      :disabled="disabled"
      :readonly="readonly"
      :rounded="rounded"
      :flat="flat"
      :clearable="clearable"
      :loading="loading"
      :error-messages="errors[0]"
      :dense="dense"
      hide-details
      solo
      autocomplete="off"
      @input="onInput"
      @keydown="onKeyDown"
      @click:append="$emit('click:append')"
      @keydown.enter.prevent="submit"
      @change="onBlur"
    >
      <template v-slot:append>
        <slot name="append"></slot>
      </template>

      <template v-slot:append-outer>
        <v-btn
          class="px-1"
          depressed
          :outlined="outlined"
          tile
          min-height="35"
          min-width="10"
          :disabled="(value >= maxValue) || disabledPlus"
          @click="increment"
          ><v-icon color="var(--primary)">mdi-plus</v-icon></v-btn
        >
      </template>

      <template v-slot:prepend>
        <v-btn
          class="px-1"
          depressed
          :outlined="outlined"
          tile
          min-height="35"
          min-width="10"
          @click="decrement"
          :disabled="value <= minValue"
          ><v-icon color="var(--primary)">mdi-minus</v-icon></v-btn
        >
      </template>
    </v-text-field>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'PlusMinusField',
  props: {
    'append-icon': {
      type: String,
      default: null,
    },
    'append-outer-icon': {
      type: String,
      default: null,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledPlus: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    hideDetail: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    maxValue: {
      type: Number,
      default: 99,
    },
    minValue: {
      type: Number,
      default: 0,
    },
    model: {
      type: [String, Number],
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    prependInnerIcon: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default: null,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'number',
    },
    vid: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 0,
    }
  },
  watch: {
    '$i18n.locale'() {
      if (this.hasErrors) {
        setTimeout(() => {
          this.validate();
        }, 250);
      }
    },
    // value() {
    //   const num = this.checkUserInput(this.value);
    //   this.$nextTick(() => {
    //     this.value = num;
    //   });
    // },
  },
  computed: {
    // value: {
    //   get() {
    //     return this.model;
    //   },
    //   set(value) {
    //     this.$emit('update:model', value);
    //     return value;
    //   },
    // },
    computedValue: {
      get() {
        return this.value;
      },
      set(value) {
        const num = this.checkUserInput(value)
        this.$emit('input', num);
      },
    },
    hasErrors() {
      if (!this.$refs.validate.errors) {
        return false;
      } else {
        if (0 < this.$refs.validate.errors.length) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    //events
    onBlur(value) {
      this.$emit('onBlur', value);
    },
    onKeyDown(value) {
      if (this.readonly) return;

      this.$emit('onKeyDown', value);

      if (13 == value.keyCode) {
        this.$emit('onEnter', true);
      }
    },
    async validate() {
      const response = await this.$refs.validate.validate();
      return response.valid;
    },
    submit() {
      this.$emit('submit');
      this.$emit('keyup:enter');
    },
    onInput(value) {
      this.$emit('onInput', this.filteredValue);
    },
    checkUserInput(value) {
      let tempValue = value;
      const isString = typeof value === 'string' || value instanceof String;

      if (isString) {
        const getNumber = value.match(/[0-9]+/g);
        if (getNumber) tempValue = getNumber[0];
      }

      const finalValue = this.checkInputRange(tempValue);

      return finalValue;
    },
    checkInputRange(value) {
      if (value) {
        const num = +value;
        if (num >= this.minValue && num <= this.maxValue) {
          return num;
        } else if (num > this.minValue) {
          return this.maxValue;
        }
      }

      return 0;
    },
    increment() {
      if (this.value >= this.maxValue) {
        return;
      }
      const value = this.value + 1;
      this.$emit('input', value);
    },
    decrement() {
      if (this.value <= this.minValue) {
        return;
      }
      const value = this.value - 1;
      this.$emit('input', value);
    },
  },
  created() {
    if (this.rules) {
      this.validated = false;
    }
  },
  mounted() {
    this.$refs.validate.$watch('errors', value => {
      if (value[0]) {
        this.$emit('onError', value[0]);
      }
    });

    // if (this.value <= this.minValue) {
    //   this.value = this.minValue;
    // }

    this.$emit('mounted');
  },
  data() {
    return {
      validated: true,
      filteredValue: 0,
    };
  },
};
</script>

<style scoped>
.v-text-field {
  border-radius: 0px;
}

.v-text-field >>> input[type='number']::-webkit-inner-spin-button,
.v-text-field >>> input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-text-field >>> input[type='number'] {
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
  appearance: none;
  -moz-appearance: textfield;
}

.input.v-text-field--solo-flat >>> .v-input__slot {
  box-shadow: none !important;
  min-height: 36px !important;
}

.v-input--is-disabled {
  opacity: 0.8;
}

/* .v-input--is-readonly {
  opacity: 1;
} */

.v-input >>> .v-input__control {
  margin-top: 0px;
}

.v-input >>> .v-input__control .v-input__slot .v-progress-linear {
  display: none;
}

.v-text-field >>> .v-input__append-outer {
  margin-left: 0px;
  margin-top: 0px !important;
}

.v-text-field >>> .v-input__append-outer .v-btn--outlined {
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.v-text-field >>> .v-input__prepend-outer {
  margin-right: 0px;
  margin-top: 0px !important;
}

.v-text-field >>> .v-input__prepend-outer .v-btn--outlined {
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.v-btn {
  border-color: var(--base-gray);
}
</style>
