import Vue from 'vue';
import { isUndefinedNullOrEmpty, setTimeZone, priceFormat } from '@/util/tools.js';

export default {
  data() {
    return {};
  },
  methods: {
    isUndefinedNullOrEmpty,
    setTimeZone,
    priceFormat,
  },
};
