import AppStorage from '@/plugins/storage/index.js';
import { isUndefinedNullOrEmpty } from '@/util/tools';

const checkout = {
  state: {
    pickup: {
      form: {
        pickupInfo: {},
        custInfo: {},
      },
    },
    delivery: {
      form: {
        deliveryInfo: {},
        quotationInfo: {},
        custInfo: {},
      },
    },
  },
  getters: {},
  mutations: {
    SET_PICKUP_INFO_FORM(state, payload) {
      state.pickup.form.pickupInfo = payload;
    },
    SET_PICKUP_CUST_INFO_FORM(state, payload) {
      state.pickup.form.custInfo = payload;
    },
    SET_DELIVERY_INFO_FORM(state, payload) {
      state.delivery.form.deliveryInfo = payload;
    },
    SET_QUOTATION_FORM(state, payload) {
      state.delivery.form.quotationInfo = payload;
    },
    SET_DELIVERY_CUST_INFO_FORM(state, payload) {
      state.delivery.form.custInfo = payload;
    },
  },
  actions: {},
};
export default checkout;
