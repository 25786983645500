const data = [
  {
    path: '/login',
    values: [99, 3211, 3206, 1105, 1111, 1129, 1102, 1104],
  },
  {
    path: 'auth/staff/login',
    values: ['NotInitialized'],
  },
  {
    path: '/first-time/login',
    values: [1131],
  },
  {
    path: '/verify/scrt/answer',
    values: [99],
  },
  {
    path: 'order/place',
    values: ['InvalidRequest', 'InvalidSignature'],
  },
];

export default data;
