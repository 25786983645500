import { routes } from './router-components';
import AppStorage from '@/plugins/storage/index.js';
import Router from 'vue-router';
import store from '../store';
import i18n from '@/translations';
import { showError } from '@/mixins/common';
import { TOKEN_TYPE } from '@/common/enum/common';
import Vue from 'vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function autoGuestLogin() {
  return store.dispatch('login_act', {}).then(res => {
    if (res.ret == '0') {
      return store.dispatch('processLoginSuccess_act', res.data);
    }
  });
}

function autoUserLogin() {
  return store.dispatch('userLogin_act', {}).then(res => {
    if (res.ret == '0') {
      return store.dispatch('processUserLoginSuccess_act', res.data);
    }
  });
}

function callUserLogin() {
  return store.getters.getMobileNo && store.getters.getUserDisplayName;
}

router.beforeEach((to, from, next) => {
  autoRefresh(to, from, next);
});

async function autoRefresh(to, from, next) {
  if (to.path.includes('menu')) {
    const current = new Date();
    const currentTimeMs = current.getTime();
    const expiry = store.state.menu.dataExp;
    if (expiry) {
      if (currentTimeMs > expiry) {
        window.location.reload();
        next(false);
      }
    }
  }

  const issuedAtTime = store.getters.getIat;
  const expireTime = store.getters.getExpireTime;
  const currentTime = new Date().getTime() / 1000;

  if (to.meta.requireAuth) {
    if (store.getters.getTokenType == TOKEN_TYPE.USER) {
      if (store.getters.getUserToken) {
        if (currentTime > expireTime) {
          // if userToken expired, auto get guest jwt to avoid api error
          if (callUserLogin()) await autoUserLogin();
          else {
            store.commit('SET_USER_TOKEN', null);
            await autoGuestLogin();
          }
        } else if ((expireTime - issuedAtTime) / 2 > expireTime - currentTime) {
          if (callUserLogin()) await autoUserLogin();
          else {
            store.commit('SET_USER_TOKEN', null);
            await autoGuestLogin();
          }
        }
      }
    } else if (store.getters.getTokenType == TOKEN_TYPE.GUEST) {
      if (store.getters.getToken) {
        // 如果有禁止访问的情况，则跳转到指定页面

        // Get token earlier before expiry or get token after expired
        if ((expireTime - issuedAtTime) / 2 > expireTime - currentTime) {
          await autoGuestLogin();
        }
        // if (currentTime > expireTime - 1800 /* Get token earlier before expiry */) {
        // }
      } else {
        await autoGuestLogin();
      }
    } else {
      await autoGuestLogin();
      return next();
    }

    if (!to.query.storeId) {
      if (from.query.storeId) {
        to.query.storeId = from.query.storeId;
        // * Somehow use next({ name: to.name }) will have issue
        next({ path: to.path, params: to.params, query: to.query });
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    if (store.getters.getToken) {
      next();
    } else {
      next();
    }
  }

  // let timer = setTimeout(() => {
  //   let storageToken = AppStorage.local.get('token');
  //   let token = store.getters.getToken;
  //   // 用户在其他页面退出或重新登录了，则刷新页面
  //   if (token && storageToken !== token) {
  //     // window.location.reload(true);
  //   }
  //   clearTimeout(timer);
  // }, 200);
}

router.afterEach(to => {
  //console.log('路由 afterEach', to)
  if (!to.meta.keepAlive) {
    window.scrollTo(0, 0);
  }
});

export default router;
