<template>
  <CommonModal v-model="show" :modal-size="modalWidth ? '' : modalSize" :modal-width="modalWidth" :title="title">
    <template #header>
      <slot name="header">
        <div class="msg-modal-header">
          <div class="d-flex justify-space-between align-center">
            <div class="msg-modal-header__title">{{ title }}</div>
            <v-icon color="#000" @click="close">mdi-close</v-icon>
          </div>
        </div>
      </slot>
    </template>
    <template #body>
      <slot name="body">
        <div class="msg-modal-body d-flex align-center">
          <div class="msg-modal-body__text">{{ msg }}</div>
        </div>
      </slot>
    </template>
    <template #footer>
      <slot name="footer">
        <div class="msg-modal-footer text-right">
          <AppBtn class="btn-black" :height="34" :loading="loading" @click="confirm">{{ $t('act.ok') }}</AppBtn>
        </div>
      </slot>
    </template>
  </CommonModal>
</template>
<script>
import CommonModal from './index';
export default {
  name: 'MessageModal',
  components: {
    CommonModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    modalSize: {
      // Options: modal-lg, modal-md, modal-sm, modal-sm-2, modal-xs
      type: String,
      default: 'modal-xs',
    },
    modalWidth: {
      type: Number,
      default: null,
    },
    msg: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>
<style lang="scss" scoped>
.msg-modal-header {
  // background-color: #fff;
  padding: 15px 15px 15px;

  &__title {
    color: #000;
    font-size: 1.25rem;
    font-weight: 600;
  }
}
.msg-modal-body {
  // background-color: #fff;
  min-height: 70px;
  line-height: 1.3;
  &__text {
    // color: var(--primary-color);
    font-size: 1.25rem;
    font-weight: 500;
    padding: 10px 15px 10px;
    // font-weight: 600;
  }
}
.msg-modal-footer {
  padding: 8px 15px 8px;
}
</style>
