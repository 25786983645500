<template>
  <v-data-table
    :class="className"
    :headers="headers"
    :items="items"
    :item-key="itemKey"
    :items-per-page="paginationConfig.size"
    :page="paginationConfig.current"
    :loading="loading"
    :loading-text="loadingText"
    :hide-default-footer="hideDefaultFooter"
    :hide-default-header="hideDefaultHeader"
    :disable-sort="disableSort"
    :mobile-breakpoint="mobileBreakpoint"
    :show-select="showSelect"
    :selectable-key="selectableKey"
    @toggle-select-all="toggleSelectAll"
    @item-selected="itemSelected"
    @update:items-per-page="itemsPerPageChg"
    @click:row="clickRow"
  >
    <template v-for="{ value } in headers" v-slot:[`header.${value}`]="{ header }">
      <slot :name="`header-${value}`" v-bind:header="header">
        {{ header.text }}
      </slot>
    </template>

    <template v-for="{ value } in headers" v-slot:[`item.${value}`]="{ item }">
      <slot :name="`item-${value}`" v-bind:item="item">
        {{ item[value] }}
      </slot>
    </template>

    <template v-slot:footer>
      <div v-if="isItemsExist" class="v-data-custom-footer d-flex justify-end align-center font-weight-bold py-3 px-5">
        <!-- <div class="text-lowercase">
          {{ `${currentPageRecordCounter} ${$t('lbl.of')} ${paginationConfig.total} ${$t('lbl.results')}` }}
        </div> -->

        <div class="custom-pagination d-flex align-center">
          <div
            class="custom-pagination__back d-flex align-center"
            :class="[isPaginationFirstPage ? 'custom-pagination--disabled' : 'custom-pagination__back--hover']"
            @click="getPreviousPagination"
          >
            <v-icon size="14">mdi-chevron-left</v-icon>
            <span>{{ $t('act.back') }}</span>
          </div>

          <div class="px-3">{{ paginationConfig.page }}</div>

          <div
            class="custom-pagination__next d-flex align-center"
            :class="[isPaginationLastPage ? 'custom-pagination--disabled' : 'custom-pagination__next--hover']"
            @click="getNextPagination"
          >
            <span>{{ $t('act.next') }}</span>
            <v-icon size="14">mdi-chevron-right</v-icon>
          </div>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { calTotalPage } from '@/util/tools';

export default {
  name: 'Datatable',
  props: {
    className: {
      type: [String, Array],
      default: null,
    },
    disableSort: {
      type: Boolean,
      default: true,
    },
    headers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hideDefaultFooter: {
      type: Boolean,
      default: true,
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: '',
    },
    mobileBreakpoint: {
      type: [Number, String],
      default: 600,
    },
    page: {
      type: Number,
      default: 1,
    },
    paginationConfig: {
      type: Object,
      default: () => ({
        page: 1,
        pageSize: 10,
        total: 0,
      }),
      validator: value => {
        return ['page', 'pageSize', 'total'].every(key => key in value);
      },
    },
    selectableKey: {
      type: String,
      default: 'isSelectable',
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    // Calculate current page records rather than computed
    // Reason: Using computed, calculation not accurate, api haven't return result
    items: {
      handler: 'calculateCurrentPageRecords',
      immediate: true,
    },
  },
  data() {
    return {
      currentPageRecordCounter: null,
    };
  },
  computed: {
    getTotalPage() {
      return calTotalPage(this.paginationConfig.total, this.paginationConfig.size);
    },
    isItemsExist() {
      return this.items.length > 0;
    },
    isPaginationFirstPage() {
      return this.paginationConfig.page === 1;
    },
    isPaginationLastPage() {
      return this.paginationConfig.page >= this.getTotalPage;
    },
  },
  methods: {
    toggleSelectAll(e) {
      this.$emit('toggleSelectAll', e);
    },
    itemSelected(e) {
      this.$emit('itemSelected', e);
    },
    itemsPerPageChg(e) {
      this.$emit('itemsPerPageChg', e);
    },
    calculateCurrentPageRecords() {
      const multipleOf = (this.paginationConfig.page - 1) * 10,
        firstRecordCounter = multipleOf + 1,
        lastRecordCounter = multipleOf + this.items.length;

      this.currentPageRecordCounter = `${firstRecordCounter} - ${lastRecordCounter}`;
    },
    getPreviousPagination() {
      if (!this.isPaginationFirstPage) {
        this.paginationConfig.page--;
        this.paginationChangeHandler();
      }
    },
    getNextPagination() {
      if (!this.isPaginationLastPage) {
        this.paginationConfig.page++;
        this.paginationChangeHandler();
      }
    },
    paginationChangeHandler() {
      this.$emit('getList');
    },
    clickRow(item, otherInfo) {
      this.$emit('click:row', item, otherInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table::v-deep {
//   border: 1px solid var(--border-lighten-gray);
//   border-radius: 4px;

//   .v-data-table-header {
//     background-color: transparent !important;
//     font-weight: 600;

//     tr {
//       th {
//         height: 40px;
//         border-bottom: 1px solid var(--border-lighten-gray) !important;
//         color: var(--text-light-gray) !important;
//         font-size: var(--font-size-sm-2);
//       }
//     }
//   }

//   & > .v-data-table__wrapper {
//     & > table {
//       & > tbody {
//         & > tr {
//           td {
//             padding-top: 15px;
//             padding-bottom: 15px;
//             color: var(--primary);
//             font-weight: 600;
//             font-size: inherit;
//           }
//         }

//         & > tr:not(.v-data-table__empty-wrapper) {
//           &:hover {
//             background-color: var(--dim-lighter-blue) !important;
//             cursor: pointer;
//           }
//         }
//       }
//     }
//   }

  border-radius: 10px;
  .v-data-table__wrapper {
    border-radius: 10px;
    thead.v-data-table-header tr th {
      background-color: var(--primary-black-color);
      color: var(--primary-color);
      font-size: 1rem;
      font-weight: 500 !important;
      height: 42px;
    }
    tbody tr {
      td {
        font-size: 0.9rem;
        height: 42px;
      }
    }
  }

  .v-data-custom-footer {
    color: var(--text-light-gray);
    font-size: var(--font-size-sm-2);
  }

  .custom-pagination {
    color: inherit;
    user-select: none;

    &__back,
    &__next {
      &--hover {
        cursor: pointer;

        &:hover {
          & > span {
            text-decoration: underline;
          }
        }
      }
    }

    &--disabled {
      opacity: 0.3;
    }
  }
}
</style>
