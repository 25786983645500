const REJECT_CODE = {
  UNKNOWN: 0,
  COMMON: 1,
  UNAUTHORISED: 2,
  WRONGKEY: 99,
  EMPTYPASS: 1129,
  EMPTYGAVERIFY: 1904,
  SETPASSWORD: 3211,
  SESSION_EXPIRED: 1131,
};

export { REJECT_CODE };
