import Api from '../api';

export const lookupApi = {
  onlinePayMethodList() {
    return Api.get('lookup?category=OnlinePayMethod', {});
  },
  getDeliveryAreaByPostcode({ grp = null }) {
    const params = {
      category: 'isDelivery',
      grp,
    };
    return Api.get('lookup', params);
  },
  getScheduleList({ grp = null }) {
    const params = {
      category: 'DeliveryTime',
      grp,
    };
    return Api.get('lookup', params);
  },
  deliveryChannelList() {
    return Api.get('lookup?category=DeliveryChannel');
  },
};
