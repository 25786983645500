export const MENU_TYPE = {
  FIRST_LAYER: 0,
  SUB_SECTION: 1,
  LOWEST_LAYER: 2,
};

export const MENU_GRP = {
  SET: 1,
  ALACARTE: 2,
  PROMOTION: 3,
  FIXED_PRICE: 4, // Fixed price for bao, except drinks. Eg. This set can choose two baos, so just show set price instead of showing price for every bao
  SPECIAL: 9,
};
