<template>
  <v-dialog v-model="show" :content-class="'common-modal ' + modalSize" :persistent="persistent" :width="modalWidth">
    <v-card>
      <slot v-if="header" name="header">
        <div class="modal-header">
          <slot name="header-content">
            <div class="d-flex justify-space-between align-center">
              <div class="modal-header__title" :style="{ ...titleStyle }">{{ title }}</div>
              <v-icon v-if="closeBtn" color="#fff" @click="close">mdi-close</v-icon>
            </div>
          </slot>
        </div>
      </slot>

      <v-divider v-if="showHeaderDivider && header" />

      <slot v-if="body" name="body">
        <div class="modal-body">
          <div class="w-100">
            <slot name="body-content"></slot>
          </div>
        </div>
      </slot>

      <v-divider v-if="showFooterDivider && footer" />

      <slot v-if="footer" name="footer">
        <div class="modal-footer">
          <slot name="footer-content"></slot>
        </div>
      </slot>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'CommonModal',
  data() {
    return {};
  },
  props: {
    body: {
      type: Boolean,
      default: true,
    },
    closeBtn: {
      type: Boolean,
      default: true,
    },
    footer: {
      type: Boolean,
      default: true,
    },
    header: {
      type: Boolean,
      default: true,
    },
    modalSize: {
      // Options: modal-lg, modal-md, modal-sm, modal-sm-2, modal-xs
      type: String,
      default: 'modal-sm',
    },
    modalWidth: {
      type: Number,
      default: null,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    showFooterDivider: {
      type: Boolean,
      default: true,
    },
    showHeaderDivider: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    titleStyle: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show: {
      get() {
        // console.log('value12312412');
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .common-modal {
    .modal-header {
      background-color: #000;
      padding: 15px 15px 15px;

      &__title {
          color: #fff;
          font-size: 1.25rem;
          font-weight: 600;
      }
    }
    .modal-body {
      color: #000;
      display: flex;
      // justify-content: center;
      padding: 10px 15px 10px;
    }
    .modal-footer {
      padding: 10px 15px 10px;
    }
    &.modal-lg {
      max-width: 1200px;
    }
    &.modal-md {
      max-width: 900px;
    }
    &.modal-sm {
      max-width: 600px;
    }
    &.modal-sm-2 {
      max-width: 500px;
    }
    &.modal-xs {
      max-width: 400px;
    }
    &.modal-xs-2 {
      max-width: 300px;
    }
  }
}
</style>
